const footerContentData = [
  {
    mainTitleEng: "Home",
    mainTitleAr: "الرئيسية",
    navPage: "/Home",
    navPageSelection: "Home",
    sections: [
      {
        nameEng: "Introduction",
        nameAr: "مقدمة",
        idNavigate: "introduction",
      },
      {
        nameEng: "Products",
        nameAr: "منتجات",
        idNavigate: "homeProducts",
      },
      {
        nameEng: "Testimonials",
        nameAr: "شهادات العملاء",
        idNavigate: "homeTestimonials",
      },
    ],
  },
  {
    mainTitleEng: "About Us",
    mainTitleAr: "عن الشركة",
    navPage: "/About",
    navPageSelection: "About",
    sections: [
      {
        nameEng: "Who We Are",
        nameAr: "من نحن",
        idNavigate: "whoWeAreSection",
      },
      {
        nameEng: "History",
        nameAr: "تاريخنا",
        idNavigate: "historySection",
      },
      {
        nameEng: "Values",
        nameAr: "قيمنا الاساسية",
        idNavigate: "valuesSection",
      },
    ],
  },
  {
    mainTitleEng: "Products",
    mainTitleAr: "منتجات",
    navPage: "/Products",
    navPageSelection: "Products",
    sections: [
      {
        nameEng: "Main Products",
        nameAr: "المنتجات الاساسية",
        idNavigate: "mainProductsSection",
      },
      {
        nameEng: "Technology",
        nameAr: "تقنيتنا",
        idNavigate: "technologySection",
      },
      {
        nameEng: "Clients",
        nameAr: "عملائنا",
        idNavigate: "clientsSection",
      },
    ],
  },
  {
    mainTitleEng: "Contact Us",
    mainTitleAr: "اتصل بنا",
    navPageSelection: "Contact",
    navPage: "/Contact",
    sections: [
      {
        nameEng: "Requests",
        nameAr: "ابق على اتصال",
        idNavigate: "requestsSection",
      },
      {
        nameEng: "Location",
        nameAr: "الموقع",
        idNavigate: "locationSection",
      },
    ],
  },
  {
    mainTitleEng: "Careers",
    mainTitleAr: "وظائف",
    navPage: "/Careers",

    navPageSelection: "Careers",
    sections: [
      {
        nameEng: "Join Our Team",
        nameAr: "انضم إلى فريقنا",
        idNavigate: "joinOurTeamSection",
      },
    ],
  },
];
const companyData = {
  email: "info@innofilament.com",
  phoneNumber: "",
  addressEng: "Block 6, Industrial Area, Shabab St, Obour City, Cairo, Egypt ",
  addressAr:
    "قطعة 6، المنطقة الصناعية، شارع الشباب، مدينة العبور، القاهرة، مصر",
};
// 👇️ named exports
export { footerContentData, companyData };
