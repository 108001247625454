import React, { useContext } from "react";
import "./welcomeBar.css";
// import facebookIcon from "../../imgs/facebookIcon.png";
// import linkedInIcon from "../../imgs/linkedInIcon.png";
// import instagramIcon from "../../imgs/instagramIcon.webp";
import { LanguageContext } from "../../context/allContext";

function WelcomeBar() {
  const { contextLanguage } = useContext(LanguageContext);

  return (
    <>
      <div className="welcomeContainer">
        <div
          className={
            contextLanguage === "ENG" ? "welcomeItems" : "welcomeItemsAR"
          }
        >
          <h4>
            {contextLanguage === "ENG"
              ? "Welcome to Innofilament!"
              : "! مرحبا بكم في إينوفيلمنت"}
          </h4>
          <div className="socialLinks">
            <img
              onClick={() => {
                window.open(
                  "https://www.facebook.com/Innofilament?mibextid=LQQJ4d",
                  "_blank"
                );
              }}
              className="socialIcon"
              src={
                "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/facebookIcon.png"
              }
              alt="facebook"
            />
            <img
              onClick={() => {
                window.open("https://instagram.com/innofilament", "_blank");
              }}
              className="socialIcon"
              src={
                "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/instagramIcon.webp"
              }
              alt="instagram"
            />
            <img
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/100977537/admin/feed/posts/",
                  "_blank"
                );
              }}
              className="socialIcon"
              src={
                "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/linkedInIcon.png"
              }
              alt="linkedIn"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default WelcomeBar;
