import "./App.css";
import React, { useState, useEffect } from "react";
// import Navbar from "./components/navbar/navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Products from "./components/products/products";
// import WelcomeBar from "./components/welecomeBar/welcomeBar";
import Home from "./components/home/home";
import About from "./components/about/about";
import Contact from "./components/contact/contact";
import { LanguageContext, SelectProduct } from "./context/allContext";
import Careers from "./components/careers/careers";
// import Footer from "./components/footer/footer";
import { mainProductsSectionData } from "./components/products/productsData";

function App() {
  const [selectedProduct, setSelectedProduct] = useState("One");
  const [theSelectedProductDetails, setTheSelectedProductDetails] = useState(
    mainProductsSectionData.products[0]
  );
  const [startIndex, setStartIndex] = useState(0);
  const [indexOfMap, setIndexOfMap] = useState(0);

  // const [contextLanguage, setContextLanguage] = useState("ENG");
  const [contextLanguage, setContextLanguage] = useState(() => {
    // setLanguage(newLanguage);
    // Save the new language preference in local storage
    const savedLanguage = localStorage.getItem("savedLanguage");
    return savedLanguage ? savedLanguage : "ENG";
  });
  const [navSelection, setNavSelection] = useState(() => {
    //
    const savedSelectedNavItem = localStorage.getItem("savedSelectedNavItem");
    return savedSelectedNavItem ? savedSelectedNavItem : "Home";
  });
  useEffect(() => {
    localStorage.setItem("savedLanguage", contextLanguage);
    // localStorage.setItem("savedSelectedNavItem", navSelection);
    // , navSelection
  }, [contextLanguage]);
  // Clear local storage on page load
  // localStorage.clear();
  return (
    <>
      <SelectProduct.Provider
        value={{
          navSelection,
          setNavSelection,
        }}
      >
        <LanguageContext.Provider
          value={{
            contextLanguage,
            setContextLanguage,
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <Home
                    indexOfMap={indexOfMap}
                    setIndexOfMap={setIndexOfMap}
                    startIndexProducts={startIndex}
                    setStartIndexProducts={setStartIndex}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    theSelectedProductDetails={theSelectedProductDetails}
                    setTheSelectedProductDetails={setTheSelectedProductDetails}
                  />
                }
              ></Route>
              <Route
                path="/Products"
                element={
                  <Products
                    selectedProduct={selectedProduct}
                    indexOfMap={indexOfMap}
                    setIndexOfMap={setIndexOfMap}
                    startIndex={startIndex}
                    setStartIndex={setStartIndex}
                    setSelectedProduct={setSelectedProduct}
                    theSelectedProductDetails={theSelectedProductDetails}
                    setTheSelectedProductDetails={setTheSelectedProductDetails}
                  />
                }
              ></Route>{" "}
              <Route
                path="/Products/:id"
                element={
                  <Products
                    selectedProduct={selectedProduct}
                    indexOfMap={indexOfMap}
                    setIndexOfMap={setIndexOfMap}
                    startIndex={startIndex}
                    setStartIndex={setStartIndex}
                    setSelectedProduct={setSelectedProduct}
                    theSelectedProductDetails={theSelectedProductDetails}
                    setTheSelectedProductDetails={setTheSelectedProductDetails}
                  />
                }
              ></Route>
              <Route
                path="/Home"
                element={
                  <Home
                    indexOfMap={indexOfMap}
                    setIndexOfMap={setIndexOfMap}
                    startIndexProducts={startIndex}
                    setStartIndexProducts={setStartIndex}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    theSelectedProductDetails={theSelectedProductDetails}
                    setTheSelectedProductDetails={setTheSelectedProductDetails}
                  />
                }
              ></Route>
              <Route
                path="/Home/:id"
                element={
                  <Home
                    indexOfMap={indexOfMap}
                    setIndexOfMap={setIndexOfMap}
                    startIndexProducts={startIndex}
                    setStartIndexProducts={setStartIndex}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    theSelectedProductDetails={theSelectedProductDetails}
                    setTheSelectedProductDetails={setTheSelectedProductDetails}
                  />
                }
              ></Route>
              <Route path="/About" element={<About />}></Route>
              <Route path="/About/:id" element={<About />}></Route>
              <Route path="/Contact" element={<Contact />}></Route>
              <Route path="/Contact/:id" element={<Contact />}></Route>
              <Route path="/Careers" element={<Careers />}></Route>
              <Route path="/Careers/:id" element={<Careers />}></Route>
            </Routes>
            {/* <Footer /> */}
          </BrowserRouter>
        </LanguageContext.Provider>
      </SelectProduct.Provider>
    </>
  );
}

export default App;
