// import positiveWorkEnvironmentIcon from "../../imgs/positiveWorkEnvironmentIcon.webp";
// import teamCollaborationIcon from "../../imgs/teamCollaborationIcon.webp";
// import supportiveManagementIcon from "../../imgs/supportiveManagmentIcon.webp";
// import employeeAppreciationIcon from "../../imgs/employeeAppreaciationIcon.webp";
// import learningAndDevelopmentIcon from "../../imgs/learningAndDevelopmentIcon.webp";
// import careerAdvanceIcon from "../../imgs/careerAdvanceIcon.webp";

const careersHeaderData = {
  titleEng: "Ignite Your Career at Innofilament",
  titleAr: "أبدأ  حياتك المهنية في إينوفيلمنت ",
  textEng:
    "Discover a world of opportunities and thrive in a collaborative, innovative, and inclusive work environment, where your skills, passion, and potential are nurtured and empowered to make a meaningful impact on Innofilament's vision for the future.",
  textAr:
    "اكتشف عالمًا من الفرص وازدهر في بيئة عمل تعاونية ومبتكرة وشاملة، حيث تتم رعاية مهاراتك وشغفك وإمكاناتك وتمكينها لإحداث تأثير مفيد من خلال رؤية إينوفيلمنت للمستقبل.",
  btnTextEng: "APPLY",
  btnTextAr: "تواصل معنا",
};
const aboutWorkingHereSection = {
  titleEng: "ABOUT WORKING HERE",
  titleAr: "حول العمل هنا",
  listOfItems: [
    {
      id: 1,
      headTitleEng: "Positive Work Environment",
      headTitleAr: "بيئة عمل إيجابية",
      subTitleEng: "Nurturing success together",
      subTitleAr: "رعاية النجاح معا",
      icon: "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/positiveWorkEnvironmentIcon.webp",
      iconStyle: { width: "40px", height: "43px", flexShrink: "0" },
    },
    {
      id: 2,
      headTitleEng: "Team Collaboration",
      headTitleAr: "تعاون الفريق",
      subTitleEng: "United we thrive in the workplace",
      subTitleAr: "متحدون و نزدهر في مكان العمل",
      icon: "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/teamCollaborationIcon.webp",
      iconStyle: { width: "42px", height: "40px", flexShrink: "0" },
    },
    {
      id: 3,
      headTitleEng: "Supportive Management",
      headTitleAr: "الإدارة الداعمة",
      subTitleEng: "Empowering leaders for success",
      subTitleAr: "تمكين القادة لتحقيق النجاح",
      icon: "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/supportiveManagmentIcon.webp",
      iconStyle: { width: "38px", height: "40px", flexShrink: "0" },
    },
    {
      id: 4,
      headTitleEng: "Employee Appreciation",
      headTitleAr: "تقدير الموظف",
      subTitleEng: "Celebrating our stars at the core",
      subTitleAr: "نحتفل بنجومنا  المتميزين",
      icon: "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/employeeAppreaciationIcon.webp",
      iconStyle: { width: "40px", height: "40px", flexShrink: "0" },
    },
    {
      id: 5,
      headTitleEng: "Learning and Development",
      headTitleAr: "التعلم و التطوير",
      subTitleEng: "Unlocking potential for continuous growth",
      subTitleAr: "إطلاق العنان لإمكانات النمو المستمر",
      icon: "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/learningAndDevelopmentIcon.webp",
      iconStyle: { width: "32px", height: "40px", flexShrink: "0" },
    },
    {
      id: 6,
      headTitleEng: "Career Advancement",
      headTitleAr: "التقدم الوظيفي",
      subTitleEng: "Paving the way to success",
      subTitleAr: "تمهيد الطريق للنجاح",
      icon: "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/careerAdvanceIcon.webp",
      iconStyle: { width: "40px", height: "24px", flexShrink: "0" },
    },
  ],
};
const quoteSectionData = {
  mainQuoteENg:
    '"Through our exceptional work, Innofilment will become the leading company in the Middle East, with the grace of God. Do you not believe in rewarding goodness with anything other than goodness?"',
  mainQuoteAr:
    '"من خلال إحساننا فى العمل، ستصبح إينوفيلمنت الشركة الرائدة في الشرق الأوسط إن شاء الله. فهل جزاء الإحسان إلا الإحسان؟"',
  ownerNameEng: "Eng. Sherif Aly",
  ownerNameAr: "م. شريف علي",
  titleEng: "Chief Executive Officer",
  titleAr: "الرئيس التنفيذي",
};
const applyJobTextData = {
  titleEng: "JOIN OUR TEAM",
  titleAr: "انضم إلى فريقنا",
  firstTextEng:
    "Thrive in a collaborative, innovative, and inclusive work environment, where your skills, passion, and potential are nurtured and empowered to make a meaningful impact .",
  firstTextAr:
    "ازدهر في بيئة عمل تعاونية ومبتكرة وشاملة، حيث تتم رعاية مهاراتك وشغفك وإمكاناتك وتمكينها لإحداث تأثير هادف.",
  secondTextEng:
    "If you have any questions, requests, or want to inquire about career opportunities, please fill out the form. Our team will promptly respond to your message shortly. We value your interest and look forward to hearing from you soon!",
  secondTextAr:
    "إذا كان لديك أي أسئلة أو طلبات أو تريد الاستفسار عن الفرص الوظيفية، يرجى ملء النموذج. سيقوم فريقنا بالرد على رسالتك على الفور . نحن نقدر اهتمامك ونتطلع إلى الاستماع إليك قريبًا!",
};
const formApplyStaticData = {
  labelNameEng: "Name*",
  labelNameAr: "الاسم*",
  placeHolderNameEng: "Enter your Name",
  placeHolderNameAr: "أدخل أسمك",
  labelEmailEng: "Email*",
  labelEmailAr: "بريد الالكتروني*",
  placeHolderEmailEng: "Enter your Email Address",
  placeHolderEmailAr: "أدخل عنوان بريدك الالكتروني",
  labelPhoneNumberEng: "Phone Number*",
  labelPhoneNumberAr: "رقم هاتفك*",
  placeHolderPhoneNumberEng: "Enter your Phone Number",
  placeHolderPhoneNumberAr: "  أدخل رقم هاتفك",
  labelCVEng: "CV*",
  labelCVAr: "السيرة الذاتية*",
  placeHolderCVEng: "Enter your CV",
  placeHolderCVAr: "أدخل سيرتك الذاتية",
  labelJobTitleEng: "Job Title*",
  labelJobTitleAr: " مسمى وظيفي*",
  placeHolderJobTitleEng: "Enter your Job Title",
  placeHolderJobTitleAr: "أدخل المسمى الوظيفي الخاص بك",
  btnTextEng: "SUBMIT",
  btnTextAr: "ارسال",
};
// 👇️ named exports
export {
  careersHeaderData,
  aboutWorkingHereSection,
  quoteSectionData,
  applyJobTextData,
  formApplyStaticData,
};
