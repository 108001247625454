import React, { useContext, useState, useEffect } from "react";
import "./about.css";
import { LanguageContext, SelectProduct } from "../../context/allContext";
import { useNavigate, useParams } from "react-router-dom";
// import leftIcon from "../../imgs/leftIconTest.webp";
// import rightIcon from "../../imgs/rightIconTest.webp";
// import visionMissionImage from "../../imgs/aboutUsImageOverlay.webp";
// import visionMissionMobileImage from "../../imgs/aboutMobilOverlay.webp";

import Footer from "../footer/footer";
import Navbar from "../navbar/navbar";
import WelcomeBar from "../welecomeBar/welcomeBar";
import {
  aboutHeaderData,
  whoWeAreSectionData,
  missionVisionData,
  ourCoreValuesData,
  areYouReadySEctionData,
} from "./aboutData";
function About() {
  const { id } = useParams();

  const navigate = useNavigate();
  const { contextLanguage } = useContext(LanguageContext);

  const { setNavSelection } = useContext(SelectProduct);
  const [sizeWebView, setSizeWebView] = useState(() => {
    const savedSizeWebView = localStorage.getItem("savedSizeWebView");
    return savedSizeWebView ? savedSizeWebView : window.innerWidth;
  });
  const [startIndex, setStartIndex] = useState(0);
  const [selectedCoreValue, setSelectedCoreValue] = useState(1);
  var itemsPerPage = sizeWebView >= 1400 ? 5 : sizeWebView > 905 ? 3 : 1;
  const visibleItems = [];
  for (let i = 0; i < itemsPerPage; i++) {
    const index = (startIndex + i) % whoWeAreSectionData.historyList.length;
    visibleItems.push(whoWeAreSectionData.historyList[index]);
  }
  const shiftItems = (count) => {
    const newIndex =
      (startIndex + count + whoWeAreSectionData.historyList.length) %
      whoWeAreSectionData.historyList.length;
    setStartIndex(newIndex);
  };
  const isMiddle = (currentIndex) =>
    currentIndex === Math.floor(visibleItems.length / 2);

  // Scroll to the section with the specified id
  const scrollToSection = () => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth > 1400) {
      setSizeWebView(window.innerWidth);
    } else if (window.innerWidth < 1400) {
      setSizeWebView(window.innerWidth);
    } else if (window.innerWidth <= 1000) {
      setSizeWebView(window.innerWidth);
    } else if (window.innerWidth < 800) {
      setSizeWebView(window.innerWidth);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    localStorage.setItem("savedSizeWebView", sizeWebView);
    scrollToSection();
  }, [sizeWebView, id]);
  return (
    <div className="about-Page">
      <WelcomeBar />
      <Navbar />
      {/* ####### ####### start page ###### */}
      <div
        className={
          contextLanguage === "ENG"
            ? "aboutHeaderSectionEng"
            : "aboutHeaderSectionAr"
        }
      >
        {/* <img
          className="aboutHeaderImage"
          id="introduction"
          src={
            contextLanguage === "ENG" ? aboutPageHeaderEng : aboutPageHeaderAr
          }
          alt="Greenery"
        /> */}
        <div
          className={
            contextLanguage === "ENG"
              ? "aboutHeaderContent"
              : "aboutHeaderContentAr"
          }
        >
          <div>
            <h4>
              {contextLanguage === "ENG"
                ? aboutHeaderData.titleEng
                : aboutHeaderData.titleAr}
            </h4>
          </div>
          <h6>
            {contextLanguage === "ENG"
              ? aboutHeaderData.textEng
              : aboutHeaderData.textAr}
          </h6>
          <button
            className={contextLanguage === "ENG" ? "btnAboutEng" : "btnAboutAr"}
            onClick={() => {
              navigate("/Contact");
              setNavSelection("Contact");
              // window.scrollTo({
              //   top: 0,
              //   behavior: "smooth", // Optional: Scroll with smooth animation
              // });
            }}
          >
            {contextLanguage === "ENG"
              ? aboutHeaderData.btnTextEng
              : aboutHeaderData.btnTextAr}
          </button>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      {/* ####### ####### WHO WE ARE section ###### */}
      <div id="whoWeAreSection" className="whoWeAreSection">
        <div
          className={
            contextLanguage === "ENG"
              ? "containerBoxWhoWeAreEng"
              : "containerBoxWhoWeAreAr"
          }
        ></div>
        <div
          className={
            contextLanguage === "ENG"
              ? "whoWeAreContentEng"
              : "whoWeAreContentAr"
          }
        >
          <div
            className={
              contextLanguage === "ENG"
                ? "containerTitleDivEng"
                : "containerTitleDivAr"
            }
          >
            <h1
              className={
                contextLanguage === "ENG"
                  ? "whoWeAreTitleEng"
                  : "whoWeAreTitleAr"
              }
            >
              {contextLanguage === "ENG"
                ? whoWeAreSectionData.titleEng
                : whoWeAreSectionData.titleAr}
            </h1>
          </div>
          <div
            className={
              contextLanguage === "ENG"
                ? "grayContainerDivSubTitleEng"
                : "grayContainerDivSubTitleAr"
            }
          >
            <h4
              className={
                contextLanguage === "ENG"
                  ? "whoWeAreSubTitleEng"
                  : "whoWeAreSubTitleAr"
              }
            >
              {contextLanguage === "ENG"
                ? whoWeAreSectionData.subTitleEng
                : whoWeAreSectionData.subTitleAr}
            </h4>
          </div>
        </div>
        <div
          id="historySection"
          className={
            contextLanguage === "ENG"
              ? "historyCardsContainerEng"
              : "historyCardsContainerAr"
          }
        >
          {/* <h1>cards content</h1> */}
          {(contextLanguage === "ENG"
            ? visibleItems
            : visibleItems.reverse()
          ).map((singleHistoryCardData, index) => (
            <div
              key={index}
              className={
                contextLanguage === "ENG"
                  ? "singleHistoryCardDivEng"
                  : "singleHistoryCardDivAr"
              }
            >
              <div
                className={
                  contextLanguage === "ENG"
                    ? "middleItemDivEng"
                    : "middleItemDivAr"
                }
              >
                {/* {isMiddle} */}
                {isMiddle(index) ? (
                  <img
                    className="left-Icon-About"
                    alt={"left-Icon"}
                    src={
                      "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/leftIconTest.webp"
                    }
                    onClick={() => shiftItems(-1)}
                  />
                ) : null}
                <h1
                  className={
                    contextLanguage === "ENG"
                      ? isMiddle(index)
                        ? "SelectedHistoryDateNumberEng"
                        : "historyDateNumberEng"
                      : isMiddle(index)
                      ? "SelectedHistoryDateNumberAr"
                      : "historyDateNumberAr"
                  }
                >
                  {contextLanguage === "ENG"
                    ? singleHistoryCardData.dateEng
                    : singleHistoryCardData.dateAr}
                </h1>
                {isMiddle(index) ? (
                  <img
                    className="right-Icon-About"
                    alt={"right-Icon"}
                    onClick={() => shiftItems(1)}
                    src={
                      "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/rightIconTest.webp"
                    }
                  />
                ) : null}
              </div>

              <h3
                className={
                  contextLanguage === "ENG"
                    ? isMiddle(index)
                      ? "SelectedHistoryTitleEng"
                      : "historyTitleEng"
                    : isMiddle(index)
                    ? "SelectedHistoryTitleAr"
                    : "historyTitleAr"
                }
              >
                {contextLanguage === "ENG"
                  ? singleHistoryCardData.titleEng
                  : singleHistoryCardData.titleAr}
              </h3>
              <h5
                className={
                  contextLanguage === "ENG"
                    ? isMiddle(index)
                      ? "SelectedHistoryDetailsEng"
                      : "historyDetailsEng"
                    : isMiddle(index)
                    ? "SelectedHistoryDetailsAr"
                    : "historyDetailsAr"
                }
              >
                {contextLanguage === "ENG"
                  ? singleHistoryCardData.detailsEng
                  : singleHistoryCardData.detailsAr}
              </h5>
            </div>
          ))}
        </div>
      </div>
      {contextLanguage === "ENG" ? (
        <div>
          <br></br>
          <br></br>
          <br></br>
        </div>
      ) : (
        <div> </div>
      )}
      {/* ####### ######################################## */}
      {/* ####### ####### Vision & Mission section ###### */}
      <div className="visionAndMissionSection">
        <div
          className={
            contextLanguage === "ENG"
              ? "containerBoxVisionMissionEng"
              : "containerBoxVisionMissionAr"
          }
        ></div>
        <div
          className={
            contextLanguage === "ENG"
              ? "visionAndMissionContentDivEng"
              : "visionAndMissionContentDivAr"
          }
        >
          <div
            className={
              contextLanguage === "ENG"
                ? "image-container-Eng"
                : "image-container-Ar"
            }
          >
            <img
              src={
                sizeWebView <= 1000
                  ? "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/aboutMobilOverlay.webp"
                  : "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/aboutUsImageOverlay.webp"
              }
              alt="greenImage"
              className={contextLanguage === "ENG" ? "image-Eng" : "image-Ar"}
            />
            <div
              className={
                contextLanguage === "ENG"
                  ? "text-overlay-Eng"
                  : "text-overlay-Ar"
              }
            >
              <p
                className={
                  contextLanguage === "ENG"
                    ? "iconImageTextEng"
                    : "iconImageTextAr"
                }
              >
                “
              </p>
              <h1
                className={
                  contextLanguage === "ENG"
                    ? "imageTextDetailsEng"
                    : "imageTextDetailsAr"
                }
              >
                {contextLanguage === "ENG"
                  ? missionVisionData.imageDataEng
                  : missionVisionData.imageDataAr}
              </h1>
            </div>
          </div>

          <div
            className={
              contextLanguage === "ENG"
                ? "missionAndVisionCardsEng"
                : "missionAndVisionCardsAr"
            }
          >
            {missionVisionData.missionVisionList.map(
              (singleCardData, index) => (
                <div
                  key={index}
                  className={
                    contextLanguage === "ENG"
                      ? "singleMissionOrVisionStyleEng"
                      : "singleMissionOrVisionStyleAr"
                  }
                >
                  <div className="iconImage">
                    <img
                      alt={singleCardData.titleEng}
                      src={singleCardData.icon}
                      className="iconStyleMisVis"
                    />
                  </div>
                  <div
                    className={
                      contextLanguage === "ENG"
                        ? "singleDataContentEng"
                        : "singleDataContentAr"
                    }
                  >
                    <div className="divAroundTextVisionMission">
                      <h1
                        className={
                          contextLanguage === "ENG"
                            ? "singleDataContentTitleEng"
                            : "singleDataContentTitleAr"
                        }
                      >
                        {contextLanguage === "ENG"
                          ? singleCardData.titleEng
                          : singleCardData.titleAr}
                      </h1>
                    </div>

                    <h3
                      className={
                        contextLanguage === "ENG"
                          ? "singleDataContentTextEng"
                          : "singleDataContentTextAr"
                      }
                    >
                      {contextLanguage === "ENG"
                        ? singleCardData.textEng
                        : singleCardData.textAr}
                    </h3>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>{" "}
      <br></br>
      {/* ####### ######################################## */}
      {/* ####### ####### core values section ###### */}
      <div id="valuesSection" className="coreValuesSection">
        <div
          className={
            contextLanguage === "ENG"
              ? "coreValuesContentEng"
              : "coreValuesContentAr"
          }
        >
          {/* left Side */}
          <div
            className={
              contextLanguage === "ENG"
                ? "coreValuesDetailsLeftEng"
                : "coreValuesDetailsLeftAr"
            }
          >
            <div
              className={
                contextLanguage === "ENG"
                  ? "leftSideCoreValueDataEng"
                  : "leftSideCoreValueDataAr"
              }
            >
              <h1
                className={
                  contextLanguage === "ENG"
                    ? "leftSideCoreTitleEng"
                    : "leftSideCoreTitleAr"
                }
              >
                {contextLanguage === "ENG"
                  ? ourCoreValuesData.mainTitleEng
                  : ourCoreValuesData.mainTitleAr}
              </h1>
              <h3
                className={
                  contextLanguage === "ENG"
                    ? "leftSideCoreSubTitleEng"
                    : "leftSideCoreSubTitleAr"
                }
              >
                {contextLanguage === "ENG"
                  ? ourCoreValuesData.mainSubTitleEng
                  : ourCoreValuesData.mainSubTitleAr}
              </h3>
              <h3
                className={
                  contextLanguage === "ENG"
                    ? "leftSideCoreNumberEng"
                    : "leftSideCoreNumberAr"
                }
              >
                {contextLanguage === "ENG"
                  ? ourCoreValuesData.numberDetailsEng
                  : ourCoreValuesData.numberDetailsAr}
              </h3>
              <h3
                className={
                  contextLanguage === "ENG"
                    ? "leftSideCoreNumberTextEng"
                    : "leftSideCoreNumberTextAr"
                }
              >
                {contextLanguage === "ENG"
                  ? ourCoreValuesData.numberTextEng
                  : ourCoreValuesData.numberTextAr}
              </h3>
              <button
                className="btnCoreValue"
                onClick={() => {
                  navigate("/Contact");
                  setNavSelection("Contact");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth", // Optional: Scroll with smooth animation
                  });
                }}
              >
                {contextLanguage === "ENG"
                  ? aboutHeaderData.btnTextEng
                  : aboutHeaderData.btnTextAr}
              </button>
            </div>
          </div>
          {/* right cards Side */}
          <div
            className={
              contextLanguage === "ENG"
                ? "coreValuesCardsRightEng"
                : "coreValuesCardsRightAr"
            }
          >
            {ourCoreValuesData.coreValuesList.map((singleCardData, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelectedCoreValue(singleCardData.id);
                }}
                className={
                  contextLanguage === "ENG"
                    ? // ? selectedCoreValue === singleCardData.id
                      "singleSelectedCardStyleEng"
                    : // : "singleCardStyleEng"
                      // selectedCoreValue === singleCardData.id
                      // ?
                      "singleSelectedCardStyleAr"
                  // : "singleCardStyleAr"
                }
              >
                <div className="backGroundCircleIcon">
                  <img
                    alt={singleCardData.titleEng}
                    src={singleCardData.icon}
                    className="iconCoreValueStyle"
                  />
                </div>
                <h1
                  className={
                    contextLanguage === "ENG"
                      ? "singleCardValuesTitleEng"
                      : "singleCardValuesTitleAr"
                  }
                >
                  {contextLanguage === "ENG"
                    ? singleCardData.titleEng
                    : singleCardData.titleAr}
                </h1>
                <h3
                  className={
                    contextLanguage === "ENG"
                      ? "singleCardValuesTextEng"
                      : "singleCardValuesTextAr"
                  }
                >
                  {contextLanguage === "ENG"
                    ? singleCardData.textEng
                    : singleCardData.textAr}
                </h3>
              </div>
            ))}
          </div>
        </div>
      </div>{" "}
      <br></br>
      <br></br>
      <br></br>
      {/* ####### ######################################## */}
      {/* ####### ####### are ready  section ###### */}
      <div className="areYouReadySection">
        <div
          className={
            contextLanguage === "ENG"
              ? "containerBoxAreYouReadyEng"
              : "containerBoxAreYouReadyAr"
          }
        ></div>
        <div
          className={
            contextLanguage === "ENG"
              ? "areYouReadyContentDataDivEng"
              : "areYouReadyContentDataDivAr"
          }
        >
          <h1
            className={
              contextLanguage === "ENG"
                ? "areYouReadyContentTitleEng"
                : "areYouReadyContentTitleAr"
            }
          >
            {contextLanguage === "ENG"
              ? areYouReadySEctionData.titleEng
              : areYouReadySEctionData.titleAr}
          </h1>
          <h3
            className={
              contextLanguage === "ENG"
                ? "areYouReadyContentTextEng"
                : "areYouReadyContentTextAr"
            }
          >
            {contextLanguage === "ENG"
              ? areYouReadySEctionData.textEng
              : areYouReadySEctionData.textAr}
          </h3>
          <button
            className="btnAreYouReady"
            onClick={() => {
              navigate("/Contact");
              setNavSelection("Contact");
              window.scrollTo({
                top: 0,
                behavior: "smooth", // Optional: Scroll with smooth animation
              });
            }}
          >
            {contextLanguage === "ENG"
              ? areYouReadySEctionData.btnTextEng
              : areYouReadySEctionData.btnTextAr}
          </button>
        </div>
      </div>
      <div className="About-Footer-Section">
        <Footer />
      </div>
    </div>
  );
}

export default About;
