import React, { useState, useContext, useEffect } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
// import logo from "../../imgs/logoImage.png";
// import arabicLogo from "../../imgs/arabicLogo.webp";
// import sideMenuIcon from "../../imgs/sideMenuIcon.webp";
// import exitIcon from "../../imgs/exit-Icon-Nav.webp";
import { LanguageContext, SelectProduct } from "../../context/allContext";

function Navbar() {
  const { contextLanguage, setContextLanguage } = useContext(LanguageContext);
  const { navSelection, setNavSelection } = useContext(SelectProduct);
  // const [isMobile, setIsMobile] = useState(false);

  // const [navSelection, setNavSelection] = useState("Products");
  // const [navSelection, setNavSelection] = useState(() => {
  //   //
  //   const savedSelectedNavItem = localStorage.getItem("savedSelectedNavItem");
  //   return savedSelectedNavItem ? savedSelectedNavItem : "Products";
  // });
  const [navbarCollapse, setNavbarCollapse] = useState(false);
  const toggleSideMenu = () => {
    setNavbarCollapse(!navbarCollapse);
  };
  //choose the screen size
  // const handleResize = () => {
  //   if (window.innerWidth < 955) {
  //     setIsMobile(true);
  //   } else {
  //     setIsMobile(false);
  //   }
  // };
  const [sizeWebView, setSizeWebView] = useState(() => {
    //
    const savedSizeWebView = localStorage.getItem("savedSizeWebView");
    return savedSizeWebView ? savedSizeWebView : window.innerWidth;
  });
  const handleResize = () => {
    if (window.innerWidth > 1400) {
      setSizeWebView(window.innerWidth);
    } else if (window.innerWidth < 955) {
      setSizeWebView(window.innerWidth);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    localStorage.setItem("savedSizeWebView", sizeWebView);
  }, [sizeWebView]);

  return (
    <>
      <div className="navContainer">
        <nav className={contextLanguage === "ENG" ? "navBar" : "navBarAr"}>
          <div className="logo">
            <img
              className={
                contextLanguage === "ENG" ? "logoImage-Eng" : "logoImage-Ar"
              }
              src={
                contextLanguage === "ENG"
                  ? "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/logoImage.png"
                  : "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newArabicLogoFooter.webp"
              }
              alt="logo"
            />
          </div>
          <div
            className={
              contextLanguage === "ENG"
                ? sizeWebView < 955 && navbarCollapse
                  ? "navListMobile"
                  : "navList"
                : sizeWebView < 955 && navbarCollapse
                ? "navListArMobile"
                : "navListAr"
            }
          >
            <Link
              onClick={() => {
                toggleSideMenu();
                setNavSelection("Home");
              }}
              className={navSelection === "Home" ? "selected" : "link"}
              to={"/Home"}
            >
              {contextLanguage === "عربي" ? "الرئيسية" : "Home"}
            </Link>
            <Link
              onClick={() => {
                toggleSideMenu();
                setNavSelection("About");
              }}
              className={navSelection === "About" ? "selected" : "link"}
              to={"/About"}
            >
              {contextLanguage === "عربي" ? "عن الشركة" : "About Us"}
            </Link>
            <Link
              onClick={() => {
                toggleSideMenu();
                setNavSelection("Products");
              }}
              className={navSelection === "Products" ? "selected" : "link"}
              to={"/Products"}
            >
              {contextLanguage === "عربي" ? `${"منتجات "}` : "Products"}
            </Link>
            <Link
              onClick={() => {
                toggleSideMenu();
                setNavSelection("Contact");
              }}
              className={navSelection === "Contact" ? "selected" : "link"}
              to={"/Contact"}
            >
              {contextLanguage === "عربي" ? "اتصل بنا" : "Contact Us"}
            </Link>
            <Link
              onClick={() => {
                toggleSideMenu();
                setNavSelection("Careers");
              }}
              className={navSelection === "Careers" ? "selected" : "link"}
              to={"/Careers"}
            >
              {contextLanguage === "عربي" ? "وظائف" : "Careers"}
            </Link>
            {sizeWebView < 955 && navbarCollapse ? (
              <Link
                onClick={() => {
                  toggleSideMenu();
                }}
                className={"link"}
              >
                <img
                  className="exitIcon"
                  src={
                    "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/exit-Icon-Nav.webp"
                  }
                  alt="menu"
                />
              </Link>
            ) : null}
          </div>

          <div
            className={
              contextLanguage === "ENG" ? "display-Menu" : "display-Menu-Ar"
            }
          >
            <h4
              onClick={() => {
                setContextLanguage(contextLanguage === "عربي" ? "ENG" : "عربي");
                // window.location.reload();
              }}
              className={contextLanguage === "عربي" ? "languageAr" : "language"}
            >
              {contextLanguage === "عربي" ? "ENG" : "عربي"}
            </h4>
            <div
              onClick={() => {
                toggleSideMenu();
              }}
              className="side"
            >
              <img
                className={
                  contextLanguage === "ENG"
                    ? "sideMenuIcon-Eng"
                    : "sideMenuIcon-Ar"
                }
                src={
                  "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/sideMenuIcon.webp"
                }
                alt="menu"
              />
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
