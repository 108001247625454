// import shadeNetting from "../../imgs/shadeNetting.webp";
// import shadeNettingSelected from "../../imgs/shadeNettingSelected.webp";
// import protectionBags from "../../imgs/protectionBags.webp";
// import protectionBagsSelected from "../../imgs/protectionBagsSelected.webp";
// import waterFilters from "../../imgs/waterFilters.webp";
// import waterFiltersSelected from "../../imgs/waterFiltersSelected.webp";
// import monofilamentYarn from "../../imgs/spoolOfThread.webp";
// import monofilamentYarnSelected from "../../imgs/spoolOfThreadSelected.webp";
// import shadeNettingDetailsImageOne from "../../imgs/shadeNettingDetailsImageOne.webp";
// import shadeNettingDetailsImageTwo from "../../imgs/shadeNettingPRoductTwo.webp";
// import shadeNettingDetailsImageThree from "../../imgs/shadeNettingProductThree.webp";
// import shadeNettingDetailsImageFour from "../../imgs/shadeNettingPRoductFour.webp";
// import shadeNettingDetailsImageFive from "../../imgs/shadeNettingPRoductFive.webp";
// import protectionsBagsDetailsImageOne from "../../imgs/protectionsBagsDetailsImageOne.webp";
// import protectionBagsTwo from "../../imgs/protectionBagsTwo.webp";
// import protectionBagsThree from "../../imgs/protectionBagsThree.webp";
// import protectionBagsFour from "../../imgs/protectionBagsFour.webp";
// import waterWellFiltersDetailsImageOne from "../../imgs/waterWellFiltersDetailsImageOne.webp";
// import filtersImageTwo from "../../imgs/filtersImageTwo.webp";
// import filtersImageThree from "../../imgs/filtersImageThree.webp";
// import filtersImageFour from "../../imgs/filtersImageFour.webp";
// import filtersImageFive from "../../imgs/filtersImageFive.webp";
// import monofilamentImageOne from "../../imgs/monofilamentImage.webp";
// import monofielmentYarnTwo from "../../imgs/monofielmentYarnTwo.webp";
// import monofielmentYarnThree from "../../imgs/monofielmentYarnThree.webp";
// import monofielmentYarnFour from "../../imgs/monofielmentYarnFour.webp";
// import monofielmentYarnFive from "../../imgs/monofielmentYarnFive.webp";
// import monofielmentYarnSex from "../../imgs/monofielmentYarnSex.webp";
// import monofielmentYarnSeven from "../../imgs/monofielmentYarnSeven.webp";
// import monofielmentYarnEight from "../../imgs/monofielmentYarnEight.webp";
// import firstImageShadeNetting from "../../imgs/firstImageShadeNetting.webp";
// import latestShadeNetting from "../../imgs/latestShadeNetting.webp";
// import newProtectionBagsOne from "../../imgs/newProtectionBagsOne.webp";
// import newProtectionBagsTwo from "../../imgs/newProtectionBagsTwo.webp";
// import newProtectionBagsThree from "../../imgs/newProtectionBagsThree.webp";
// import newProtectionBagsFour from "../../imgs/newProtectionBagsFour.webp";
// import lastProtectionBags from "../../imgs/lastProtectionBags.webp";
// import newWaterWellFiltersRealOne from "../../imgs/newWaterWellFiltersRealOne.webp";
// import newWaterWellFiltersRealTwo from "../../imgs/newWaterWellFiltersRealTwo.webp";
const startSectionData = {
  // title1Eng: "MONOFILAMENT",
  title1Eng: "PREMIUM",

  title2Eng: "MONOFILAMENT",
  // title2Eng: "PRODUCTION",
  title1Ar: " مونوفيلمنت ",
  title2Ar: "عالي الجودة",
  textEng:
    "Pioneers in the production of shade nets, filters, protection and packaging bags. We care about creating a healthy and safe environment for all is a fundamental principle in our culture.",
  textAr:
    "نحن نهتم بخلق بيئة صحية وآمنة للجميع، وهو مبدأ أساسي في ثقافتنا. نحن رواد في إنتاج شبك التظليل وفلاتر آبار المياه وشكاير الحماية .",
  btnText: "Contact Us",
  btnTextAr: "اتصل بنا",
};
const mainProductsSectionData = {
  mainTitleEng: "OUR MAIN PRODUCTS",
  mainTitleAr: "منتجاتنا الأساسية",
  products: [
    {
      id: "One",
      titleEng: "Shade Netting",
      titleAr: "شبك التظليل",
      descriptionEng: "Optimal protection and strength",
      descriptionAr: "الحماية المثلى والقوة",
      image:
        "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNetting.webp",
      selectedImage:
        "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingSelected.webp",
      imageStyle: {
        backgroundColor: "#f7f7f7",
        height: "72px",
        width: "65px",
        marginTop: "10px",
      },
      imageStyleResponsive: {
        backgroundColor: "#f7f7f7",
        height: "65px",
        width: "60px",
        marginTop: "8px",
      },
      detailsEng: {
        name: "Shade Netting",
        description:
          "One of the most important products that are made of plastic yarns is greenhouse covers. Greenhouse covers are manufactured by breaking plastic threads through tailoring machines that specialize in plastic threads. The method of detailing greenhouse covers varies in terms of the planting areas for each greenhouse.",
        specs: [
          "Available in all colors",
          "Custom  sizes",
          "Quality ( Shade percentage - Life time - Based on crop - UV)",
        ],
        images: [
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/firstImageShadeNetting.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/firstImageShadeNetting.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/latestShadeNetting.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/latestShadeNetting.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingPRoductTwo.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingPRoductTwo.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingProductThree.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingProductThree.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingPRoductFour.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingPRoductFour.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingPRoductFive.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingPRoductFive.webp",
          },
        ],
      },
      detailsAr: {
        name: "شبك التظليل",
        description:
          "واحدة من أهم المنتجات التي تصنع من خيوط البلاستيك هي أغطية الصوب الزراعية. يتم تصنيع أغطية الصوب الزراعية عن طريق تمزيق الخيوط المنونوفيلمنت من خلال آلات الخياطة المتخصصة في الخيوط المنونوفيلمنت. تختلف طريقة تفصيل أغطية الصوب الزراعية من حيث مناطق الزراعة لكل بيت زراعي.",
        specs: [
          "متوفر بجميع الألوان",
          "أحجام مخصصة",
          "الجودة (نسبة الظل - فترة الاستخدام - استنادًا إلى النباتات - الأشعة فوق البنفسجية)",
        ],
        images: [
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/firstImageShadeNetting.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/firstImageShadeNetting.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/latestShadeNetting.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/latestShadeNetting.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingPRoductTwo.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingPRoductTwo.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingProductThree.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingProductThree.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingPRoductFour.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingPRoductFour.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingPRoductFive.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/shadeNettingPRoductFive.webp",
          },
        ],
      },
    },
    {
      id: "Two",
      titleEng: "Protection Bags",
      titleAr: "أكياس الحماية",
      descriptionEng: "Exceptional protection bags",
      descriptionAr: "أكياس حماية استثنائية",
      image:
        "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/protectionBags.webp",
      selectedImage:
        "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/protectionBagsSelected.webp",
      imageStyle: {
        backgroundColor: "#f7f7f7",
        height: "75px",
        width: "75px",
        marginTop: "10px",
      },
      imageStyleResponsive: {
        backgroundColor: "#f7f7f7",
        height: "65px",
        width: "65px",
        marginTop: "8px",
      },
      detailsEng: {
        name: "Protection Bags",
        description:
          "Protective bags are used to cover dates to protect them from some of the damage that may be caused by the general climate. Dates are protected from insects and birds that cause damage to a large proportion of the crop. The use of protective films to cover dates spares the use of pesticides.",
        specs: [
          "Sizes (50 - 70 - 75 - 80 - 100 cm) Length",
          "Custom width",
          "Quality ( Shade percentage - Life time - UV)",
        ],
        images: [
          // {
          //   original:
          //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/protectionsBagsDetailsImageOne.webp",
          //   thumbnail:
          //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/protectionsBagsDetailsImageOne.webp",
          // },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsOne.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsOne.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsTwo.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsTwo.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsThree.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsThree.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsFour.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsFour.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/lastProtectionBags.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/lastProtectionBags.webp",
          },

          // {
          //   original:
          //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/protectionBagsTwo.webp",
          //   thumbnail:
          //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/protectionBagsTwo.webp",
          // },
          // {
          //   original:
          //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/protectionBagsThree.webp",
          //   thumbnail:
          //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/protectionBagsThree.webp",
          // },
          // {
          //   original:
          //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/protectionBagsFour.webp",
          //   thumbnail:
          //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/protectionBagsFour.webp",
          // },
        ],
      },
      detailsAr: {
        name: "أكياس الحماية",
        description:
          "تستخدم أكياس الحماية لتغطية التمور لحمايتها من بعض الأضرار التي قد تسببها الظروف المناخية العامة. يحمي التمور من الحشرات والطيور التي تسبب ضرراً لنسبة كبيرة من المحصول. استخدام الأغشية الواقية لتغطية التمور يوفر استخدام المبيدات الحشرية.",
        specs: [
          "الأحجام (٥٠ - ٧٠ -٧٥ - ٨٠ - ١٠٠ سم) الطول",
          "عرض مخصص",
          "الجودة (نسبة الظل - العمر الافتراضي - الأشعة فوق البنفسجية)",
        ],
        images: [
          // {
          //   original:
          //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/protectionsBagsDetailsImageOne.webp",
          //   thumbnail:
          //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/protectionsBagsDetailsImageOne.webp",
          // },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsOne.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsOne.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsTwo.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsTwo.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsThree.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsThree.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsFour.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newProtectionBagsFour.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/lastProtectionBags.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/lastProtectionBags.webp",
          },
        ],
      },
    },
    {
      id: "Three",
      titleEng: "Water Well Filters",
      titleAr: "فلاتر آبار المياه",
      descriptionEng: "Clean water, essential filters",
      descriptionAr: "مياه نظيفة ، فلاتر ضرورية",
      image:
        "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/waterFilters.webp",
      selectedImage:
        "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/waterFiltersSelected.webp",
      imageStyle: {
        backgroundColor: "#f7f7f7",
        height: "75px",
        width: "75px",
        marginTop: "10px",
      },
      imageStyleResponsive: {
        backgroundColor: "#f7f7f7",
        height: "65px",
        width: "65px",
        marginTop: "8px",
      },
      detailsEng: {
        name: "Water Well Filters",
        description:
          "One of the products that are also manufactured from monofilament are water well filters. The filters are manufactured by separating monofilament by specialized machines such as separating the agricultural sieve covers, protective plates and filling. Filters are manufactured on large roll form and are cut and detailed according to the length and width of the filter depending on the depth and width of the dug well.",
        specs: [
          "Sizes (20 - 26 - 33 - 42 - 45 - 50 - 56 - 68 - 70 cm)",
          "Quality according to mesh size and weight",
        ],
        images: [
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newWaterWellFiltersRealOne.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newWaterWellFiltersRealOne.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newWaterWellFiltersRealTwo.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newWaterWellFiltersRealTwo.webp",
          },
          // {
          //   original:
          //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageTwo.webp",
          //   thumbnail:
          //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageTwo.webp",
          // },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageThree.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageThree.webp",
          },

          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageFour.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageFour.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageFive.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageFive.webp",
          },
        ],
      },
      detailsAr: {
        name: "فلاتر آبار المياه",
        description:
          "يتم تصنيع الفلاتر عن طريق تفصيل المونوفيلمنت من خلال ماكينات متخصصة كالتي تفصل أغطية الصوب الزراعية و شكاير الحماية. يتم تصنيع الفلاتر على هيئة رولات كبيرة الحجم و يتم قصها و تفصيلها بنائاً علي طول و عرض الفلتر كما ان يعتمد طول و عرض الفلتر على عمق و عرض البئر المحفور.",
        specs: [
          "المقاسات (٢٠ - ٢٦ - ٣٣- ٤٢ - ٤٥ - ٥٠ - ٥٨ - ٦٨ - ٧٠ سم)",
          "الجودة حسب حجم الشبكة والوزن",
        ],
        images: [
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newWaterWellFiltersRealOne.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newWaterWellFiltersRealOne.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newWaterWellFiltersRealTwo.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newWaterWellFiltersRealTwo.webp",
          },
          // {
          //   original:
          //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageTwo.webp",
          //   thumbnail:
          //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageTwo.webp",
          // },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageThree.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageThree.webp",
          },

          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageFour.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageFour.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageFive.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/filtersImageFive.webp",
          },
        ],
      },
    },
    {
      id: "Four",
      titleEng: "Monofilament Yarns",
      titleAr: "خيوط مونوفيلمنت",
      descriptionEng: "High-Quality and durability",
      descriptionAr: "جودة عالية ومتانة",
      image:
        "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/spoolOfThread.webp",
      selectedImage:
        "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/spoolOfThreadSelected.webp",
      imageStyle: {
        backgroundColor: "#f7f7f7",
        height: "80px",
        width: "45px",
        marginTop: "10px",
      },
      imageStyleResponsive: {
        backgroundColor: "#f7f7f7",
        height: "70px",
        width: "40px",
        marginTop: "8px",
      },
      detailsEng: {
        name: "Monofilament Yarns",
        description:
          "Plastic thread (monofilament) can be sold as-is or used and detailed on detailing machines to produce other products, the most important of which are greenhouse covers, protection and packing bags, and water well filters.",
        specs: [
          "High Density Polyethylene & Polypropylene",
          "Thickness of Filaments (120 - 350 micron)",
          "Product capacity (all colors)",
        ],
        images: [
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofilamentImage.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofilamentImage.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnTwo.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnTwo.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnThree.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnThree.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnFour.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnFour.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnFive.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnFive.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnSex.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnSex.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnSeven.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnSeven.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnEight.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnEight.webp",
          },
        ],
      },
      detailsAr: {
        name: "خيوط مونوفيلمنت",
        description:
          "يعتبر المنتج الأساسي لشركة إينوفيلمنت هو خيط البلاستيك المصنوع من البولي إيثيلين و يتم تصنيعه بماكينة سحب الخيوط. يمكن بيع الخيوط البلاستيكية (المونوفيلمينت) كما هي أو تفصيلها على آلات التفصيل لإنتاج منتجات أخرى وأهمها أغطية الصوب الزراعية وشكاير الحماية وفلاتر آبار المياه",
        specs: [
          "البولي إيثيلين والبولي بروبيلين عالي الكثافة",
          "السمك (120 - 350 ميكرون)",
          "لون المنتج (جميع الألوان)",
        ],
        images: [
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofilamentImage.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofilamentImage.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnTwo.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnTwo.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnThree.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnThree.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnFour.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnFour.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnFive.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnFive.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnSex.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnSex.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnSeven.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnSeven.webp",
          },
          {
            original:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnEight.webp",
            thumbnail:
              "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/monofielmentYarnEight.webp",
          },
        ],
      },
    },
  ],
};
const OurTechnologySection = {
  titleEng: "OUR TECHNOLOGY",
  titleAr: "تقنيتنا",
  textEng:
    "Innofilment manufactures plastic threads (monofilament) from the high-density polyethylene raw material as we have indicated before. We pull high-density polyethylene inside a machine called (Monofilament Extruder), heat it and pass it through a helix with narrow passages under high pressure that comes out in the form of threads.",
  textAr:
    "تقوم شركة إنوفيلمنت بتصنيع خيوط المنونوفيلمنت من مادة البولي إيثيلين الخام عالي الكثافة ، حيث نقوم بسحب مادة البولي إيثيلين عالي الكثافة ونقوم بتسخينها وتمريرها عبر حلزون بممرات ضيقة تحت ضغط عالي، الذي يخرج على شكل خيوط.",
};
// 👇️ named exports
export { startSectionData, mainProductsSectionData, OurTechnologySection };
