const contactHeaderData = {
  titleEng: "Get in touch with us",
  titleAr: "ابق على تواصل معنا",
  textEng:
    "Reach Out to Our Dedicated Team of Experts and Experience Outstanding Customer Service, Prompt Assistance, and Collaborative Solutions Tailored to Your Needs.",
  textAr:
    "تواصل مع فريقنا المتخصص من الخبراء واختبر خدمة العملاء المتميزة والمساعدة الفورية والحلول التعاونية المصممة خصيصًا لتلبية احتياجاتك.",
  btnTextEng: "APPLY",
  btnTextAr: "تواصل معنا",
};
const keepInTouchSection = {
  titleEng: "KEEP IN TOUCH",
  titleAr: "ابق على اتصال",
  textEng:
    "If you have any questions, requests, or want to inquire about career opportunities, please fill out the form. Our team will promptly respond to your message shortly. We value your interest and look forward to hearing from you soon!",
  textAr:
    "إذا كان لديك أي أسئلة أو طلبات أو تريد الاستفسار عن الفرص الوظيفية، يرجى ملء النموذج. سيقوم فريقنا بالرد على رسالتك على الفور . نحن نقدر اهتمامك ونتطلع إلى الاستماع إليك !",
  addressEng: "Block 6, Industrial Area, Shabab St, Obour City, Cairo, Egypt ",
  addressAr:
    "قطعة 6، المنطقة الصناعية، شارع الشباب، مدينة العبور، القاهرة، مصر",

  email: "info@innofilament.com",
  phoneNumberEng: "01022108000",
  phoneNumberAr: "٠١٠٢٢١٠٨٠٠٠",
};

const formStaticData = {
  labelNameEng: "Name*",
  labelNameAr: "الاسم*",
  placeHolderNameEng: "Enter your Name",
  placeHolderNameAr: "أدخل أسمك",
  labelEmailEng: "Email*",
  labelEmailAr: "بريد الالكتروني*",
  placeHolderEmailEng: "Enter your Email Address",
  placeHolderEmailAr: "أدخل عنوان بريدك الالكتروني",
  labelPhoneNumberEng: "Phone Number*",
  labelPhoneNumberAr: "رقم هاتفك*",
  placeHolderPhoneNumberEng: "Enter your Phone Number",
  placeHolderPhoneNumberAr: "  أدخل رقم هاتفك",
  labelMessageEng: "Message*",
  labelMessageAr: "رسالتك*",
  placeHolderMessageEng: "Enter your Message",
  placeHolderMessageAr: " أدخل رسالتك",
  btnTextEng: "SUBMIT",
  btnTextAr: "ارسال",
};
// 👇️ named exports
export { contactHeaderData, formStaticData, keepInTouchSection };
