// import ourMissionIcon from "../../imgs/ourMissionIcon.webp";
// import trialAboutIcon from "../../imgs/trialAboutIcon.webp";
// import ourVisionIcon from "../../imgs/ourVisionIcon.webp";
// import trialTwoAboutIcon from "../../imgs/trialTwoAboutIcon.webp";
// import developingLivesIcon from "../../imgs/developingLivesIcon.webp";
// import promotingSafetyIcon from "../../imgs/promotingSafetyIcon.webp";
// import buildingNationsIcon from "../../imgs/buildingNationsIcon.webp";
// import extendingHandsIcon from "../../imgs/extendingHandsIcon.webp";
const aboutHeaderData = {
  titleEng: "Our Commitment to Excellence",
  titleAr: "التزامنا بالتميز",
  textEng:
    "At our monofilament yarn factory, we take pride in being a leader in the industry, specializing in the manufacturing of monofilament yarns for shade netting, filters, and protection bags. ",
  textAr:
    "في مصنعنا لخيوط مونوفيلمنت نفخر بأننا رائدون فى صناعة البلاستيك، متخصصون في تصنيع خيوط المونوفيلمنت  لشبك التظليل، فلاتر آبار المياه ، وشكاير الحماية.",

  btnTextEng: "ENQUIRE",
  btnTextAr: "استفسر الآن",
};
const whoWeAreSectionData = {
  titleEng: "WHO WE ARE?",
  titleAr: "من نحن؟",
  subTitleEng:
    "A group of technicians, Engineers and investors united to create an Egyptian industrial entity to enhance and develop the Egyptian economy, putting Egypt on the map amongst the biggest industrial countries throughout the world. We approached the plastic industry serving the agriculture field due to its huge impact on the countries’ economy.",
  subTitleAr:
    "مجموعة من الفنيين والمهندسين والمستثمرين اجتمعوا لإنشاء كيان صناعي مصري لتعزيز وتطوير الاقتصاد المصري، ووضع مصر على الخريطة بين أكبر الدول الصناعية في جميع أنحاء العالم. اقتربنا من صناعة البلاستيك لخدمة قطاع الزراعة نظرًا لتأثيرها الهائل على اقتصاد البلاد.",
  historyList: [
    {
      id: 1,
      dateEng: "1996",
      dateAr: "١٩٩٦",
      titleEng: "Establishment",
      titleAr: "التأسيس",
      detailsEng:
        "The factory was owned by Abdullatif Al-Sharif Group for Plastics (ASG), and it was the second factory in Egypt in this field and one of the leading companies in this industry.",
      detailsAr:
        "كان المصنع مملوكًا لمجموعة عبد اللطيف الشريف للبلاستيك (ASG)، وكان المصنع الثاني في مصر في هذا المجال وأحد الشركات الرائدة في هذه الصناعة.",
    },
    {
      id: 2,
      dateEng: "2018",
      dateAr: "٢٠١٨",
      titleEng: "Our Beginning",
      titleAr: "بدايتنا",
      detailsEng:
        "Our company was established in late 2018. At the beginning of the year 2019 we took over Abdullatif Al-Sharif Group for plastics (ASG).",
      detailsAr:
        "تأسست شركتنا في أواخر عام 2018. في بداية عام 2019، قمنا بالاستحواذ على مجموعة عبد اللطيف الشريف للبلاستيك (ASG).",
    },
    {
      id: 3,
      dateEng: "2019",
      dateAr: "٢٠١٩",
      titleEng: "Transformation",
      titleAr: "التحول",
      detailsEng:
        "We made a deal with ASG shareholders to take over their business, machines, and market share. This agreement marked a transformative milestone for our organization.",
      detailsAr:
        "أبرمنا اتفاقًا مع مساهمي شركة ASG للاستحواذ على أعمالهم وآلاتهم وحصة السوق. هذا الاتفاق كان محطة تحويلية هامة منظمتنا.",
    },
    {
      id: 4,
      dateEng: "2020",
      dateAr: "٢٠٢٠",
      titleEng: "Market Testing",
      titleAr: "اختبار السوق",
      detailsEng:
        "We started to penetrate the Egyptian market and tested it to be able to start taking over the market throughout the testing phase, we remained agile and adaptable.",
      detailsAr:
        "بدأنا في اختراق السوق المصرية اختبرناها لنتمكن من السيطرة على السوق خلال مرحلة الاختبار. كنا متجاوبين وقابلين للتكييف خلال هذه المرحلة.",
    },
    {
      id: 5,
      dateEng: "2023",
      dateAr: "٢٠٢٣",
      titleEng: "Currently",
      titleAr: "الآن",
      detailsEng:
        "We are currently focused on client satisfaction and continuously improving our products and proprietary technology.",
      detailsAr:
        "  تركيزنا الان على ارضاء العملاء ونعمل باستمرار على تحسين منتجاتنا والتكنولوجيا العصرية.",
    },
  ],
};
const missionVisionData = {
  imageDataEng: "PROMOTES A GREENER, HEALTHIER FUTURE ",
  imageDataAr: "نعزز مستقبلًا أكثر خضرة وصحة",
  missionVisionList: [
    {
      id: 1,
      titleEng: "OUR MISSION",
      titleAr: "رسالتنا",
      icon: "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/trialTwoAboutIcon.webp",
      textEng:
        "Empowering Egyptian agriculture with exceptional monofilament products; leading the way in agricultural shading nets, HDPE mesh bags and well filters, driving efficiency, sustainability, and prosperity.",
      textAr:
        "تمكين الزراعة المصرية بواسطة منتجات خيوط المنونوفيلمنت  الاستثنائية؛ نقود طريقنا في شبكات التظليل الزراعية، وشكاير الحماية من البولي ايثيلين عالي الكثافة، وفلاتر آبار المياه، لتعزيز الكفاءة والاستدامة والازدهار.",
    },
    {
      id: 2,
      titleEng: "OUR VISION",
      titleAr: "رؤيتنا",
      icon: "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/trialAboutIcon.webp",
      textEng:
        "Revolutionizing the monofilament industry, as the global choice for innovative agricultural shading nets, HDPE mesh bags and well filters, empowering farmers, protecting resources, and propelling Egypt's agricultural sector forward.",
      textAr:
        "ثورة في صناعة خيوط المنونوفيلمنت ، كخيار عالمي لشبكات التظليل الزراعية المبتكرة، وشكاير الحماية من البولي ايثيلين عالي الكثافة، وفلاتر آبار المياه، لتمكين المزارعين، وحماية الموارد، ودفع قطاع الزراعة في مصر للأمام.",
    },
  ],
};
const ourCoreValuesData = {
  mainTitleEng: "OUR CORE VALUES",
  mainTitleAr: "قيمنا الأساسية",
  mainSubTitleEng: "Guided by Integrity, Innovation, and Customer Excellence.",
  mainSubTitleAr: "مسترشدين بالنزاهة والابتكار وتميز العملاء.",
  numberDetailsEng: "27+",
  numberDetailsAr: "٢٧+",
  numberTextEng: "Years experience in field.",
  numberTextAr: "خبرة سنوات في المجال.",
  btnTextEng: "ENQUIRE",
  btnTextAr: "استفسر الآن",
  coreValuesList: [
    {
      id: 1,
      titleEng: "Building Nations",
      titleAr: "بناء الأمم",
      textEng:
        "We will focus on providing services that will enhance the performance of leading industries to build a strong self-sustained nation",
      textAr:
        "سنركز على تقديم خدمات تعزز أداء الصناعات الرائدة لبناء أمة قوية ومستدامة ذاتية الاكتفاء.",
      icon: "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/buildingNationsIcon.webp",
    },
    {
      id: 2,
      titleEng: "Promoting Safety",
      titleAr: "تعزيز السلامة",
      textEng:
        "Creating a safe environment for everyone is part of our culture ",
      textAr: "إن خلق بيئة آمنة للجميع هو جزء من ثقافتنا",
      icon: "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/promotingSafetyIcon.webp ",
    },
    {
      id: 3,
      titleEng: "Extending Hands",
      titleAr: "تمديد الأيدي",
      textEng: "our hands will always be extended for support and cooperation",
      textAr: "وأيادينا دائماً ممدودة للدعم والتعاون",
      icon: "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/extendingHandsIcon.webp",
    },
    {
      id: 4,
      titleEng: "Developing Lives",
      titleAr: "تطوير الحياة",
      textEng: "we will always seek to develop ourselves and our people",
      textAr: "وسنسعى دائمًا إلى تطوير أنفسنا وشعبنا",
      icon: "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/developingLivesIcon.webp",
    },
  ],
};
const areYouReadySEctionData = {
  titleEng: "Are you ready to work with us?",
  titleAr: "هل أنتم مستعدون للتعاون معنا؟",
  textEng:
    "When it comes to sourcing monofilament yarns for shade netting, filters, and protection bags, partnering with our factory ensures uncompromising quality and reliability",
  textAr:
    "فيما يتعلق بتوفير خيوط المنونوفيلمنت  لشبكات التظليل وفلاتر آبار المياه وشكاير الحماية، فإن الشراكة مع مصنعنا تضمن الجودة والموثوقية الفائقة.",
  btnTextEng: "REQUEST A QUOTE",
  btnTextAr: "طلب عرض أسعار",
};
// 👇️ named exports
export {
  aboutHeaderData,
  whoWeAreSectionData,
  missionVisionData,
  ourCoreValuesData,
  areYouReadySEctionData,
};
