import React, { useContext, useState, useEffect } from "react";
import "./products.css";
// import leftIcon from "../../imgs/leftIcon.webp";
// import rightIcon from "../../imgs/rightIcon.webp";
// import specsIconGreen from "../../imgs/specsIconGreen.webp";
// import zadnaClientImage from "../../imgs/zadnaClientImage.webp";
// import siwaClientLogo from "../../imgs/siwaClientLogo.webp";
// import nakhlatyClientLogo from "../../imgs/nakhlatyClientLogo.webp";
// import picoClientLogo from "../../imgs/picoClientLogo.webp";
// import lineClientImage from "../../imgs/lineClientImage.webp";
// import freshFarmClientImage from "../../imgs/freshFarmClientImage.webp";
// import dinaFarmClientImage from "../../imgs/dinaFarmClientImage.webp";
// import royalFarmClientImage from "../../imgs/royalFarmClientImage.webp";
import ImageGallery from "react-image-gallery";

import {
  startSectionData,
  mainProductsSectionData,
  OurTechnologySection,
} from "./productsData";
import { LanguageContext, SelectProduct } from "../../context/allContext";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../footer/footer";
import Navbar from "../navbar/navbar";
import WelcomeBar from "../welecomeBar/welcomeBar";
function Products({
  selectedProduct,
  setSelectedProduct,
  indexOfMap,
  setIndexOfMap,
  startIndex,
  setStartIndex,
  theSelectedProductDetails,
  setTheSelectedProductDetails,
}) {
  // const intervalTime = 3000;
  const itemsClients = [
    {
      image:
        "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/zadnaClientImage.webp",
      style: { width: "97px", height: "103px", flexShrink: "0" },
    },
    {
      image:
        "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/siwaClientLogo.webp",
      style: { width: "190px", height: "118px", flexShrink: "0" },
    },
    {
      image:
        "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/nakhlatyClientLogo.webp",
      style: { width: "73px", height: "109px", flexShrink: "0" },
    },
    {
      image:
        "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/picoClientLogo.webp",
      style: { width: "200px", height: "103px", flexShrink: "0" },
    },

    // "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/tawlimaClientImage.webp",
    // "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/lineClientImage.webp",
    // "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/freshFarmClientImage.webp",
    // "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/royalFarmClientImage.webp",
    // "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/dinaFarmClientImage.webp",
  ];

  const videoUrl =
    "https://www.youtube.com/embed/tOW6krwejTc?si=fNxNlTAZtzJjL2Ly"; // Replace VIDEO_ID with your actual video ID
  const { id } = useParams();
  // Scroll to the section with the specified id
  const scrollToSection = () => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const navigate = useNavigate();
  const { contextLanguage } = useContext(LanguageContext);

  const { setNavSelection } = useContext(SelectProduct);
  // const [selectedProduct, setSelectedProduct] = useState("One");
  // const [selectedProduct, setSelectedProduct] = useState(() => {
  //   const savedSelectedProduct = localStorage.getItem("selectedProduct");
  //   return savedSelectedProduct ? savedSelectedProduct : "One";
  // });
  // const [theSelectedProductDetails, setTheSelectedProductDetails] = useState(
  //   mainProductsSectionData.products[0]
  // );
  // const [theSelectedProductDetails, setTheSelectedProductDetails] = useState(
  //   // mainProductsSectionData.products[0]
  //   () => {
  //     const savedTheSelectedProductDetails = localStorage.getItem(
  //       "theSelectedProductDetails"
  //     );
  //     return savedTheSelectedProductDetails
  //       ? savedTheSelectedProductDetails
  //       : mainProductsSectionData.products[0];
  //   }
  // );
  // const [indexOfMap, setIndexOfMap] = useState(0);
  // const [isMobile, setIsMobile] = useState(() => {
  //   //
  //   const savedIsMobile = localStorage.getItem("savedIsMobile");
  //   return savedIsMobile ? savedIsMobile : false;
  // });
  const [sizeWebView, setSizeWebView] = useState(() => {
    //
    const savedSizeWebView = localStorage.getItem("savedSizeWebView");
    return savedSizeWebView ? savedSizeWebView : window.innerWidth;
  });
  // const [startIndex, setStartIndex] = useState(0);
  // const [currentIndex, setCurrentIndex] = useState(0);

  var itemsPerPage = sizeWebView >= 1400 ? 4 : sizeWebView <= 1045 ? 2 : 3;
  const shiftItems = (count) => {
    const newIndex =
      (startIndex + count + mainProductsSectionData.products.length) %
      mainProductsSectionData.products.length;
    setStartIndex(newIndex);
    setIndexOfMap(newIndex);
    setSelectedProduct(mainProductsSectionData.products[newIndex].id);
    setTheSelectedProductDetails(mainProductsSectionData.products[newIndex]);
  };

  const visibleItems = [];
  for (let i = 0; i < itemsPerPage; i++) {
    const index = (startIndex + i) % mainProductsSectionData.products.length;
    visibleItems.push(mainProductsSectionData.products[index]);
  }
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth > 1400) {
      setSizeWebView(window.innerWidth);
    } else if (window.innerWidth < 1400) {
      setSizeWebView(window.innerWidth);
    } else if (window.innerWidth < 800) {
      setSizeWebView(window.innerWidth);
    }

    // if (window.innerWidth < 800) {
    //   setIsMobile(true);
    // } else {
    //   setIsMobile(false);
    // }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // , [selectedProduct]
    // const interval = setInterval(nextItem, intervalTime);
    localStorage.setItem("savedSizeWebView", sizeWebView);
    // localStorage.setItem("savedIsMobile", isMobile);
    // , isMobile]
    // localStorage.setItem(
    //   "theSelectedProductDetails",
    //   theSelectedProductDetails
    // );

    // Clear the interval on component unmount to prevent memory leaks
    // return () => clearInterval(interval);
    // nextItem,
    scrollToSection();
  }, [sizeWebView, id]);

  return (
    <div className="Product-Page">
      <WelcomeBar />
      <Navbar />
      {/* ####### ####### Section ###### */}
      {/* ####### ####### start page ###### */}
      <div
        className={
          contextLanguage === "ENG"
            ? "photographyProductsEng"
            : "photographyProductsAr"
        }
      >
        {/* <img
          className="productsImage"
          src={contextLanguage === "ENG" ? productsImageEng : productsImageAR}
          alt="Greenery"
        /> */}
        <div
          className={
            contextLanguage === "ENG" ? "productsContent" : "productsContentAr"
          }
        >
          <div>
            <h4>
              {contextLanguage === "ENG"
                ? startSectionData.title1Eng
                : startSectionData.title1Ar}
            </h4>
            <h4>
              {contextLanguage === "ENG"
                ? startSectionData.title2Eng
                : startSectionData.title2Ar}
            </h4>
          </div>
          <h6>
            {contextLanguage === "ENG"
              ? startSectionData.textEng
              : startSectionData.textAr}
          </h6>
          <button
            className={
              contextLanguage === "ENG" ? "btn-ENQUIREEng" : "btn-ENQUIREAr"
            }
            onClick={() => {
              navigate("/Contact");
              setNavSelection("Contact");
            }}
          >
            {contextLanguage === "ENG"
              ? startSectionData.btnText.toUpperCase()
              : startSectionData.btnTextAr}
          </button>
        </div>
      </div>
      {/* ####### ####### Section ###### */}
      {/* Main Products Section */}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="productsCarouselFullSection">
        {!(sizeWebView < 800) ? <div className="boxContainer"></div> : null}
        {!(sizeWebView < 800) ? (
          <div className="mainProductsContainer" id="mainProductsSection">
            <div
              className={
                contextLanguage === "ENG" ? "main-textEng" : "main-textAr"
              }
            >
              <h1
                className={
                  contextLanguage === "ENG" ? "mainTitleEng" : "mainTitleAr"
                }
              >
                {contextLanguage === "ENG"
                  ? mainProductsSectionData.mainTitleEng
                  : mainProductsSectionData.mainTitleAr}
              </h1>
            </div>
          </div>
        ) : null}
        {!(sizeWebView < 800) ? (
          <div
            className={
              contextLanguage === "ENG"
                ? "products-containerEng"
                : "products-containerAR"
            }
          >
            {sizeWebView >= 1400 ? null : (
              <div
                onClick={() => {
                  shiftItems(-1);
                  // startIndex
                }}
                className={"Carousel-web-view-left"}
                // onClick={
                //   () => setStartIndex(0)
                //   // shiftLeft
                // }
              >
                <img
                  className="left-Icon-web"
                  alt={"left-Icon-web"}
                  src={
                    "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/leftIcon.webp"
                  }
                />
              </div>
            )}
            {visibleItems
              // .slice(
              //   startIndex,
              // sizeWebView >= 1400
              //   ? 4
              //     : startIndex === 0
              //     ? sizeWebView <= 1045
              //       ? 2
              //       : 3
              //     : 4
              // )
              // sizeWebView >= 1400
              // .slice(startIndex, mainProductsSectionData.products.length)
              .map((singleProduct, index) => (
                <div
                  onClick={() => {
                    setSelectedProduct(singleProduct.id);
                    setTheSelectedProductDetails(singleProduct);
                  }}
                  key={index}
                  className="product-style-card"
                >
                  <div className="image-background">
                    <img
                      // className="product-single-image"
                      alt={
                        contextLanguage === "ENG"
                          ? singleProduct.titleEng
                          : singleProduct.titleAr
                      }
                      style={singleProduct.imageStyle}
                      src={
                        selectedProduct === singleProduct.id
                          ? singleProduct.selectedImage
                          : singleProduct.image
                      }
                    />
                  </div>
                  <h1
                    className={
                      contextLanguage === "ENG"
                        ? "product-title-Eng"
                        : "product-title-Ar"
                    }
                  >
                    {contextLanguage === "ENG"
                      ? singleProduct.titleEng
                      : singleProduct.titleAr}
                  </h1>
                  <p
                    className={
                      contextLanguage === "ENG"
                        ? "product-desc-Eng"
                        : "product-desc-Ar"
                    }
                  >
                    {contextLanguage === "ENG"
                      ? singleProduct.descriptionEng
                      : singleProduct.descriptionAr}
                  </p>
                </div>
              ))}
            {sizeWebView >= 1400 ? null : (
              <div
                // className={
                //   contextLanguage === "ENG"
                //     ? "Carousel-web-view-right"
                //     : "Carousel-web-view-right-Ar"
                // }
                className={"Carousel-web-view-right"}
                onClick={() => shiftItems(1)}
                // onClick={
                //   () => {
                //     if (sizeWebView >= 1045) {
                //       setStartIndex(1);
                //     } else {
                //       setStartIndex(2);
                //     }
                //   }
                //   // shiftRight
                // }
              >
                <img
                  className="right-Icon-web"
                  alt={"right-Icon-web"}
                  src={
                    "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/rightIcon.webp"
                  }
                />
              </div>
            )}
          </div>
        ) : null}
        {sizeWebView < 800 ? (
          <div
            className={"products-Box-Carousel"}
            id="mainProductsSection"
          ></div>
        ) : null}
        {sizeWebView < 800 ? (
          <div className={"products-Carousel-Title"} id="mainProductsSection">
            <div
              className={
                contextLanguage === "ENG"
                  ? "main-Carousel-textEng"
                  : "main-Carousel-textAr"
              }
            >
              <h1
                className={
                  contextLanguage === "ENG" ? "mainTitleEng" : "mainTitleAr"
                }
              >
                {contextLanguage === "ENG"
                  ? mainProductsSectionData.mainTitleEng
                  : mainProductsSectionData.mainTitleAr}
              </h1>
            </div>
          </div>
        ) : null}
        {sizeWebView < 800 ? (
          <div
            className={
              contextLanguage === "ENG"
                ? "products-Carousel-Container-Eng"
                : "products-Carousel-Container-Ar"
            }
          >
            <div
              onClick={() => {
                // setIndexOfMap(
                //   (indexOfMap + 1) % mainProductsSectionData.products.length
                // );
                // setSelectedProduct(
                //   mainProductsSectionData.products[
                //     (indexOfMap + 1) % mainProductsSectionData.products.length
                //   ].id
                // );
                // setTheSelectedProductDetails(
                //   mainProductsSectionData.products[
                //     (indexOfMap + 1) % mainProductsSectionData.products.length
                //   ]
                // );
                shiftItems(-1);
              }}
            >
              <img
                className="left-Icon"
                alt={"left-Icon"}
                src={
                  "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/leftIcon.webp"
                }
              />
            </div>

            <div
              onClick={() => {
                setSelectedProduct(
                  mainProductsSectionData.products[indexOfMap].id
                );
              }}
              className="single-product-card-carousel"
            >
              <div className="image-background-carousel">
                <img
                  // className="product-single-image"
                  alt={
                    contextLanguage === "ENG"
                      ? mainProductsSectionData.products[indexOfMap].titleEng
                      : mainProductsSectionData.products[indexOfMap].titleAr
                  }
                  style={
                    sizeWebView < 800
                      ? mainProductsSectionData.products[indexOfMap]
                          .imageStyleResponsive
                      : mainProductsSectionData.products[indexOfMap].imageStyle
                  }
                  src={
                    selectedProduct ===
                    mainProductsSectionData.products[indexOfMap].id
                      ? mainProductsSectionData.products[indexOfMap]
                          .selectedImage
                      : mainProductsSectionData.products[indexOfMap].image
                  }
                />
              </div>
              <h1
                className={
                  contextLanguage === "ENG"
                    ? "product-title-Eng"
                    : "product-title-Ar"
                }
              >
                {contextLanguage === "ENG"
                  ? mainProductsSectionData.products[indexOfMap].titleEng
                  : mainProductsSectionData.products[indexOfMap].titleAr}
              </h1>
              <p
                className={
                  contextLanguage === "ENG"
                    ? "product-desc-Eng"
                    : "product-desc-Ar"
                }
              >
                {contextLanguage === "ENG"
                  ? mainProductsSectionData.products[indexOfMap].descriptionEng
                  : mainProductsSectionData.products[indexOfMap].descriptionAr}
              </p>
            </div>

            <div
              onClick={() => {
                shiftItems(1);
                // setIndexOfMap(
                //   (indexOfMap + 1) % mainProductsSectionData.products.length
                // );
                // setSelectedProduct(
                //   mainProductsSectionData.products[
                //     (indexOfMap + 1) % mainProductsSectionData.products.length
                //   ].id
                // );
                // setTheSelectedProductDetails(
                //   mainProductsSectionData.products[
                //     (indexOfMap + 1) % mainProductsSectionData.products.length
                //   ]
                // );
              }}
            >
              <img
                className="right-Icon"
                alt={"right-Icon"}
                src={
                  "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/rightIcon.webp"
                }
              />
            </div>
          </div>
        ) : null}
      </div>
      {/* <br></br>
      <br></br>
      <br></br>
      <br></br> */}
      {/* ####### ####### Section ######  */}
      {/*   Details Section    */}
      <div className="productDetailsSection">
        {/* <h1> Details Section</h1> */}
        <div
          className={
            contextLanguage === "ENG"
              ? "productDetailsContainer-Eng"
              : "productDetailsContainer-Ar"
          }
        >
          <div className="galleryCOntainer">
            <ImageGallery
              showBullets={true}
              showIndex={true}
              // lazyLoad={true}
              showPlayButton={false}
              showNav={true}
              showFullscreenButton={false}
              thumbnailPosition={"bottom"}
              items={theSelectedProductDetails.detailsEng.images}
            />
          </div>

          <div
            className={
              contextLanguage === "ENG" ? "data-section-Eng" : "data-section-Ar"
            }
          >
            <h1
              className={
                contextLanguage === "ENG"
                  ? "data-Section-Title-Eng"
                  : "data-Section-Title-Ar"
              }
            >
              {contextLanguage === "ENG"
                ? theSelectedProductDetails.detailsEng.name
                : theSelectedProductDetails.detailsAr.name}
            </h1>
            <h4
              className={
                contextLanguage === "ENG"
                  ? "data-Section-Desc-Eng"
                  : "data-Section-Desc-AR"
              }
            >
              {contextLanguage === "ENG"
                ? theSelectedProductDetails.detailsEng.description
                : theSelectedProductDetails.detailsAr.description}
            </h4>
            {(contextLanguage === "ENG"
              ? theSelectedProductDetails.detailsEng
              : theSelectedProductDetails.detailsAr
            ).specs.map((singleSpec, index) => (
              <div className="singleSpecStyle" key={index}>
                <img
                  className="specsIcon"
                  src={
                    "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/specsIconGreen.webp"
                  }
                  alt="Right-Icon"
                />
                <p
                  className={
                    contextLanguage === "ENG"
                      ? contextLanguage === "ENG" && singleSpec.includes("(")
                        ? "specParagraph-Eng-Number"
                        : "specParagraph-Eng"
                      : "specParagraph-Ar"
                  }
                >
                  {singleSpec}
                </p>
              </div>
            ))}
            <button
              className={
                contextLanguage === "ENG"
                  ? "btn-ENQUIRE-detailsEng"
                  : "btn-ENQUIRE-detailsAr"
              }
              onClick={() => {
                navigate("/Contact");
                setNavSelection("Contact");
                window.scrollTo({
                  top: 0,
                  behavior: "smooth", // Optional: Scroll with smooth animation
                });
              }}
            >
              {contextLanguage === "ENG"
                ? "ENQUIRE".toUpperCase()
                : startSectionData.btnTextAr}
            </button>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      {/* ####### ####### Section ######  */}
      {/*   Technology Section    */}
      <div className="technologySection" id="technologySection">
        <div
          className={
            contextLanguage === "ENG"
              ? "technology-content-Eng"
              : "technology-content-Ar"
          }
        >
          <div
            className={
              contextLanguage === "ENG"
                ? "left-Technology-section-Eng"
                : "left-Technology-section-Ar"
            }
          >
            <h1
              className={
                contextLanguage === "ENG"
                  ? "technologyTitle-Eng"
                  : "technologyTitle-Ar"
              }
            >
              {contextLanguage === "ENG"
                ? OurTechnologySection.titleEng
                : OurTechnologySection.titleAr}
            </h1>
            <p
              className={
                contextLanguage === "ENG"
                  ? "technologyParagraph-Eng"
                  : "technologyParagraph-Ar"
              }
            >
              {contextLanguage === "ENG"
                ? OurTechnologySection.textEng
                : OurTechnologySection.textAr}
            </p>
            <button
              className={
                contextLanguage === "ENG"
                  ? "btn-ENQUIRE-technologyEng"
                  : "btn-ENQUIRE-technologyAr"
              }
              onClick={() => {
                navigate("/Contact");
                setNavSelection("Contact");
                window.scrollTo({
                  top: 0,
                  behavior: "smooth", // Optional: Scroll with smooth animation
                });
              }}
            >
              {contextLanguage === "ENG"
                ? startSectionData.btnText.toUpperCase()
                : startSectionData.btnTextAr}
            </button>
          </div>
          <div className="right-Technology-section">
            <iframe
              src={videoUrl}
              // allowfullscreen
              className="iFrameStyle"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            />
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      {/* ####### ####### Section ######  */}
      {/*   Clients Section    */}
      <div className="clientsSection" id="clientsSection">
        <div className="carousel-clients">
          {itemsClients.map((item, index) => (
            <div
              key={index}
              // className={`carousel-item ${
              //   index === currentIndex ? "active" : ""
              // }`}
            >
              <img style={item.style} src={item.image} alt="client" />
            </div>
          ))}
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="productsFooterSection">
        <Footer />
      </div>
    </div>
  );
}

export default Products;
