import React, { useContext } from "react";
import "./footer.css";
// import logo from "../../imgs/logoImage.png";
// import arabicLogo from "../../imgs/newArabicLogoFooter.webp";
import { footerContentData, companyData } from "./footerData";
// import facebookIcon from "../../imgs/facebookIcon.png";
// import linkedInIcon from "../../imgs/linkedInIcon.png";
// import instagramIcon from "../../imgs/instagramIcon.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { LanguageContext, SelectProduct } from "../../context/allContext";
import { Link, useNavigate } from "react-router-dom";
//
function Footer() {
  const navigate = useNavigate();

  const { contextLanguage } = useContext(LanguageContext);
  const { setNavSelection } = useContext(SelectProduct);
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Scroll with smooth animation
    });
  }
  const scrollToSection = (elementID) => {
    const section = document.getElementById(elementID);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="footer-Container">
      <div
        className={
          contextLanguage === "ENG" ? "footer-Content-Eng" : "footer-Content-Ar"
        }
      >
        <div className="main-footer-section">
          <div className="logo-icon-container">
            <div className="logo-footer">
              <img
                className={
                  contextLanguage === "ENG"
                    ? "logoImageFooterEng"
                    : "logoImageFooterAr"
                }
                src={
                  contextLanguage === "ENG"
                    ? "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/logoImage.png"
                    : "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newArabicLogoFooter.webp"
                }
                alt="logo"
              />
            </div>
            <div className="IconTop">
              <FontAwesomeIcon
                className="arrow-up-icon"
                onClick={scrollToTop}
                icon={faArrowUp}
              />
            </div>
          </div>

          <div className="site-map-content">
            {footerContentData.map((singleItem, index) => {
              return (
                <div key={index} className="singleMapItem">
                  <Link
                    onClick={() => {
                      scrollToTop();
                      setNavSelection(singleItem.navPageSelection);
                    }}
                    to={singleItem.navPage}
                  >
                    <h1
                      className={
                        contextLanguage === "ENG"
                          ? "site-main-nav-Eng"
                          : "site-main-nav-Ar"
                      }
                    >
                      {contextLanguage === "ENG"
                        ? singleItem.mainTitleEng
                        : singleItem.mainTitleAr}
                    </h1>
                  </Link>

                  {singleItem.sections.map((singleSection, index) => (
                    <Link
                      key={index}
                      onClick={() => {
                        if (SelectProduct !== singleItem.navPageSelection) {
                          setNavSelection(singleItem.navPageSelection);
                        }
                        scrollToSection(singleSection.idNavigate);
                      }}
                      to={`${singleItem.navPage}/${singleSection.idNavigate}`}
                    >
                      <h3
                        key={index}
                        className={
                          contextLanguage === "ENG"
                            ? "site-nav-Eng"
                            : "site-nav-Ar"
                        }
                      >
                        {contextLanguage === "ENG"
                          ? singleSection.nameEng
                          : singleSection.nameAr}
                      </h3>
                    </Link>
                  ))}
                </div>
              );
            })}
          </div>
          <div className="companyData">
            <div className="singleData">
              <h1
                className={
                  contextLanguage === "ENG"
                    ? "singleData-Header-Eng"
                    : "singleData-Header-Ar"
                }
              >
                {contextLanguage === "ENG" ? "Email" : "البريد إلكتروني"}
              </h1>
              <h3
                className={
                  contextLanguage === "ENG"
                    ? "singleData-data-Eng"
                    : "singleData-data-Ar"
                }
              >
                {companyData.email}
              </h3>
            </div>
            <div className="singleData">
              <h1
                className={
                  contextLanguage === "ENG"
                    ? "singleData-Header-Eng"
                    : "singleData-Header-Ar"
                }
              >
                {contextLanguage === "ENG" ? "Address" : "العنوان"}
              </h1>
              <h3
                className={
                  contextLanguage === "ENG"
                    ? "singleData-data-Eng"
                    : "singleData-data-Ar"
                }
              >
                {contextLanguage === "ENG"
                  ? companyData.addressEng
                  : companyData.addressAr}
              </h3>
            </div>
          </div>
          <div className="IconTop-Large">
            <FontAwesomeIcon
              className="arrow-up-icon"
              onClick={scrollToTop}
              icon={faArrowUp}
            />
          </div>
        </div>
        <div className="hr-style">
          <hr></hr>
        </div>

        <div className="rights-section">
          <h1
            className={
              contextLanguage === "ENG" ? "rights-TextEng" : "rights-TextAr"
            }
          >
            {contextLanguage === "ENG"
              ? "INNOFILAMENT ALL RIGHTS RESERVED © 2023"
              : "إينوفيلمنت جميع الحقوق محفوظة © ٢٠٢٣"}
          </h1>
          <h1>
            {" "}
            <div className="socialLinks-footer">
              <img
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/Innofilament?mibextid=LQQJ4d",
                    "_blank"
                  );
                }}
                className="socialIcon-footer"
                src={
                  "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/facebookIcon.png"
                }
                alt="facebook"
              />
              <img
                onClick={() => {
                  window.open("https://instagram.com/innofilament", "_blank");
                }}
                className="socialIcon-footer"
                src={
                  "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/instagramIcon.webp"
                }
                alt="instagram"
              />
              <img
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/100977537/admin/feed/posts/",
                    "_blank"
                  );
                }}
                className="socialIcon-footer"
                src={
                  "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/linkedInIcon.png"
                }
                alt="linkedIn"
              />
            </div>
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Footer;
