// import employeesIcon from "../../imgs/employeesIcon.webp";
// import rangeClientsIcon from "../../imgs/rangeClientsIcon.webp";
// import experienceIcon from "../../imgs/experienceIcon.webp";
// import solutionShadeNetting from "../../imgs/solutionShadeNetting.webp";
// import solutionShadeNettingTwo from "../../imgs/solutionShadeNettingTwo.webp";
// import solutionShadeNettingThree from "../../imgs/solutionShadeNettingThree.webp";
// import solutionProtectionBags from "../../imgs/solutionProtectionBags.webp";
// import solutionProtectionBagesTwo from "../../imgs/solutionProtectionBagesTwo.webp";
// import solutionsProtectionsBagsThree from "../../imgs/solutionsProtectionsBagsThree.webp";
// import solutionWaterWell from "../../imgs/solutionWaterWell.webp";
// import solutionFiltersTwo from "../../imgs/solutionFiltersTwo.webp";
// import solutionsFIltersThree from "../../imgs/solutionsFIltersThree.webp";
// import solutionMonofilamentYarn from "../../imgs/solutionMonofilamentYarn.webp";
// import solutionsMonoFillTwo from "../../imgs/solutionsMonoFillTwo.webp";
// import solutionMonoFillThree from "../../imgs/solutionMonoFillThree.webp";
// import profileTestimonialsLogo from "../../imgs/profileTestimonialsLogo.webp";
// import secondHomeShadeNetting from "../../imgs/secondHomeShadeNetting.webp";
// import newHomeProtectionBagsOne from "../../imgs/newHomeProtectionBagsOne.webp";
// import newHomeProtectionBagsTwo from "../../imgs/newHomeProtectionBagsTwo.webp";
// import newHomeProtectionBagsThree from "../../imgs/newHomeProtectionBagsThree.webp";
// import newHomeWaterWellFiltersRealOne from "../../imgs/newHomeWaterWellFiltersRealOne.webp";
// import newHomeWaterWellFiltersRealLast from "../../imgs/newHomeWaterWellFiltersRealLast.webp";
// import lirten from "../../imgs/lirten.png";
// import smallTestSiwaLogo from "../../imgs/smallTestSiwaLogo.webp";
// import smallAvatarTestLogo from "../../imgs/smallAvatarTestLogo.webp";

const homeStartSectionData = {
  titleEng: "EMPOWERING SUCCESS WITH PREMIER PLASTIC YARN ",
  titleAr: "تمكين النجاح مع خيوط البلاستيك الرائدة",
  textEng:
    "Unleashing innovation and excellence in plastic yarn production, creating endless possibilities with superior quality, customization, and unmatched customer support",
  textAr:
    "تعمل شركتنا على تطوير وتصنيع خيوط البلاستيك عالية الجودة والمتطورة، التي تستخدم في مجموعة واسعة من الصناعات والتطبيقات. نحن نسعى جاهدين لتلبية احتياجات عملائنا من خلال تقديم منتجات مخصصة ومتفوقة في الأداء.",
  btnTextEng: "Learn More",
  btnTextAr: "أعرف أكثر",
};
const qualityHomeSectionData = {
  titleEng: "Quality, Customization, Your Source for Premium Plastic Yarn",
  titleAr: "الجودة، التخصيص، مصدرك لخيوط البلاستيك عالية الجودة",
  textEng:
    "Customer satisfaction is at the core of our business philosophy. We believe in building long-term partnerships with our clients, understanding their unique needs, and providing personalized support. Our dedicated customer service team is always ready to assist you, whether you have inquiries, require technical guidance, or need assistance with order logistics and delivery. We strive to exceed your expectations by offering prompt and efficient solutions to any challenges you may encounter.",
  textAr:
    "نحن ندرك أن كل عميل لديه احتياجات فريدة،ولذا نقدم منتج مفصل خصيصا لضمان تلبية تلك الاحتياجات بدقة. يمكننا تصميم وتنفيذ خيوط البلاستيك وفقًا للمواصفات والمتطلبات الخاصة بك، مما يضمن تلاؤمًا مثاليًا مع تطبيقاتك ومشاريعك. نحن نعتبر أنفسنا مصدرًا موثوقًا لخيوط البلاستيك عالية الجودة. نحن نستخدم مواد خام ممتازة ونعتمد على عمليات إنتاج متطورة للحصول على منتج نهائي متميز. يتم فحص جميع منتجاتنا بدقة لضمان مواصفات الجودة والموثوقية.",
  btnTextEng: "Learn More",
  btnTextAr: "تعلم أكثر",
  qualityItems: [
    {
      nameEng: "Years of Experience",
      nameAr: "سنوات من الخبرة",
      Icon: "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/experienceIcon.webp",
      iconStyleEng: {
        width: "40px",
        height: "40px",
        marginLeft: "12px",
        backgroundColor: "#f7f7f7",
      },
      iconStyleAr: {
        width: "40px",
        height: "40px",
        marginRight: "5px",
        backgroundColor: "#f7f7f7",
      },
      numberEng: "27+",
      numberAr: "+٢٧",
    },
    {
      nameEng: "Wide Range of Clients",
      nameAr: "مجموعة واسعة من العملاء",
      Icon: "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/rangeClientsIcon.webp",
      iconStyleEng: {
        width: "35px",
        height: "35px",

        marginLeft: "12px",
        backgroundColor: "#f7f7f7",
      },
      iconStyleAr: {
        width: "35px",
        height: "35px",
        marginRight: "5px",
        backgroundColor: "#f7f7f7",
      },
      numberEng: "50+",
      numberAr: "+٥٠",
    },
    {
      nameEng: "Dedicated Employees",
      nameAr: "موظفين متخصصين ",
      Icon: "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/employeesIcon.webp",
      iconStyleEng: {
        width: "18px",
        height: "40px",
        marginLeft: "20px",
        backgroundColor: "#f7f7f7",
      },
      iconStyleAr: {
        width: "16.5px",
        height: "40px",
        marginRight: "5px",
        backgroundColor: "#f7f7f7",
      },
      numberEng: "40+",
      numberAr: "+٤٠",
    },
  ],
};
const solutionsSectionData = {
  titleEng: "Tailored Solutions to Meet Your Specific Needs",
  titleAr: "حلول مصممة خصيصًا لتلبية احتياجاتك الخاصة",
  textEng:
    "We understand that each client has unique requirements and specifications for their shade netting, filters, and protection bags. As your trusted partner, we offer tailored solutions to meet your specific needs.",
  textAr:
    "نحن ندرك أن لدى كل عميل متطلبات ومواصفات فريدة لشبك التظليل والفلاتر وأكياس الحماية الخاصة بهم. كشريك موثوق به، نقدم حلولًا مصممة خصيصًا لتلبية احتياجاتك الخاصة. اعتمادًا على خبرتنا الواسعة والمهارات الفنية، نحن ملتزمون بتوفير منتجات ذات جودة عالية وحلول مخصصة تفوق توقعاتك. نحن نسعى جاهدين لتحقيق رضاك التام وتحقيق أهدافك من خلال تقديم حلول فعالة ومتخصصة لتلبية احتياجاتك الفريدة.",
  solutionsItems: [
    {
      id: "One",
      titleEng: "Shade Netting",
      titleAr: "شبك التظليل",
      descriptionEng: "Optimal protection and strength",
      descriptionAr: "حماية مثلى وقوة فائقة",
      navigation: "",
      images: [
        {
          original:
            "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/solutionShadeNetting.webp",
        },
        {
          original:
            "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/secondHomeShadeNetting.webp",
        },
        {
          original:
            "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/solutionShadeNettingThree.webp",
        },
      ],
    },
    {
      id: "Two",

      titleEng: "Protection Bags",
      titleAr: "أكياس الحماية",
      descriptionEng: "Exceptional protection bags",
      descriptionAr: "أكياس حماية استثنائية",
      navigation: "",
      images: [
        {
          original:
            "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/solutionProtectionBags.webp",
        },
        {
          original:
            "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newHomeProtectionBagsOne.webp",
        },
        {
          original:
            "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newHomeProtectionBagsThree.webp",
        },

        // {
        //   original:
        //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/solutionProtectionBagesTwo.webp",
        // },
        // {
        //   original:
        //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/solutionsProtectionsBagsThree.webp",
        // },
      ],
    },
    {
      id: "Three",

      titleEng: "Water Well Filters",
      titleAr: "فلاتر آبار المياه",
      descriptionEng: "Clean water, essential filters",
      descriptionAr: "مياه نظيفة ، فلاتر أساسية",
      navigation: "",
      images: [
        {
          original:
            "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newHomeWaterWellFiltersRealOne.webp",
        },
        {
          original:
            "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/newHomeWaterWellFiltersRealLast.webp",
        },
        {
          original:
            "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/solutionsFIltersThree.webp",
        },
      ],
    },
    {
      id: "Four",

      titleEng: "Monofilament Yarns",
      titleAr: "خيوط مونوفيلمنت",
      descriptionEng: "High-Quality and durability",
      descriptionAr: "جودة عالية ومتانة",
      navigation: "",
      images: [
        {
          original:
            "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/solutionMonofilamentYarn.webp",
        },
        {
          original:
            "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/solutionsMonoFillTwo.webp",
        },
        {
          original:
            "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/solutionMonoFillThree.webp",
        },
      ],
    },
  ],
};
const workingWithUsSection = {
  titleEng: "WHY WORK WITH US?",
  titleAr: "لماذا العمل معنا؟",
  items: [
    {
      idEng: "1",
      idAr: "١",
      titleEng: "Cutting Edge Technology",
      titleAr: "تكنولوجيا متطورة",
      descriptionEng:
        "Our state-of-the-art manufacturing facilities are equipped with cutting-edge machinery and equipment, allowing us to produce high-quality monofilament yarns.",
      descriptionAr:
        "تتمتع مرافق التصنيع الخاصة بنا بأحدث الآلات والمعدات، مما يتيح لنا إنتاج خيوط مونوفيلمنت عالية الجودة.",
    },
    {
      idEng: "2",
      idAr: "٢",
      titleEng: "Client Satisfaction",
      titleAr: "رضاء العملاء",
      descriptionEng:
        "Our top priority is the success and satisfaction of our clients. We are dedicated to building long-lasting partnerships based on trust, transparency, and mutual growth.",
      descriptionAr:
        "أولويتنا الأساسية هي نجاح ورضا عملائنا. نحن ملتزمون ببناء شراكات طويلة الأمد مبنية على الثقة والشفافية والنمو المتبادل.",
    },
    {
      idEng: "3",
      idAr: "٣",
      titleEng: "Innovative Solution",
      titleAr: "حلول مبتكرة",
      descriptionEng:
        "Partnering with Us means gaining access to innovative solutions that can transform your business. We stay at the forefront of industry trends.",
      descriptionAr:
        "التعاون معنا يعني الحصول على حلول مبتكرة تساعدك في تطوير أعمالك.   نحن نبقى في طليعة اتجاهات الصناعة.",
    },
  ],
};
const testimonialsSectionData = {
  titleEng: "CLIENTS TESTIMONIALS",
  titleAr: "شهادات العملاء",
  textEng:
    "At Innofilament, we are proud of the positive impact we have on our clients' businesses.",
  textAr:
    "في إينوفيلمنت، نحن فخورون بالتأثير الإيجابي الذي نحدثه على أعمال عملائنا.",
  testimonialsListData: [
    {
      id: 1,
      ownerEng: "Dr. Osama Kamel",
      ownerAr: "د/  أسامة كامل ",
      jobEng: "Founder, Integrated Agricultural Development",
      jobAr: " مؤسس شركة التنمية الزراعية  ",

      image:
        "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/smallAvatarTestLogo.webp",
      style: {
        width: "20px",
        height: "20px",
        flexShrink: "0",
        objectFit: "cover",
      },
      textEng:
        "Innofilament's products undeniably enhance acreage productivity, notably for fruit and palm crops, mitigating losses from climate change, insects, and birds.",
      textAr:
        "منتجات إينوفيلمنت تعزز بشكل كبير إنتاجية الفدان، خاصة لمحاصيل الفاكهة والنخيل، بتقليل الخسائر بسبب التغير المناخي والحشرات والطيور.",
    },
    {
      id: 2,
      ownerEng: "Mr. Mohamed Eissa",
      ownerAr: "أ /  محمد عيسى",
      jobEng: "General Manager, Palm Siwa",
      jobAr: "مدير عام شركة بالم سيوة",
      image:
        "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/smallTestSiwaLogo.webp",
      style: {
        width: "49px",
        height: "28px",
        flexShrink: "0",
        objectFit: "cover",
      },
      textEng:
        "Innofilament has always been a success partner; demonstrating nothing but honesty, and ingenuity, all while being customer-oriented.",
      textAr:
        "لقد كانت شركة إينوفيلمنت دائمًا شريك النجاح؛ تتحلى بالصدق والبراعة، مع التركيز على إحتياجات العملاء.",
    },
    {
      id: 3,
      ownerEng: "Mr. Bassem Bakr",
      ownerAr: " أ /  باسم بكر",
      jobEng: "Agricultural Consultant, Fruit and Palm Trees",
      jobAr: "مستشار زراعي لأشجار الفاكهة والنخيل ",

      image:
        "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/smallAvatarTestLogo.webp",
      style: {
        width: "20px",
        height: "20px",
        flexShrink: "0",
        objectFit: "cover",
      },
      textEng:
        "Innofilament uses excpetional raw materials that provide unrivalled sun shading percentage for crops, whilst using cutting edge technologies to deliver very competitive shading results.",
      textAr:
        "تستخدم شركة إينوفيلمنت مواد خام استثنائية توفر نسبة تظليل لا مثيل لها للمحاصيل، مع استخدام أحدث التقنيات لتحقيق نتائج تظليل تنافسية للغاية.",
    },
    // {
    //   id: 4,
    //   ownerEng: "Khalid Zidan",
    //   ownerAr: "خالد زيدان",
    //   jobEng: "Chief Executive Officer",
    //   jobAr: "الرئيس التنفيذي",
    //   image:
    //     "https://innofilamentwebsite.s3.me-south-1.amazonaws.com/profileTestimonialsLogo.webp",

    //   textEng:
    //     "Lorem ipsum dolor sit amet consectetur. Consectetur nisl elementum feugiat augue vitae pulvinar ipsum quam.",
    //   textAr:
    //     "لوريم إيبسوم دولور سيت أميت، كونسيكتيتور نسل إلمنتوم فوجيات أوغي فيتا بولفينار إبسوم قوام. ان",
    // },
  ],
};
const requestProfileData = {
  titleEng: "Request Company Profile",
  titleAr: "اطلع على ملف شركتنا",
  subTitleEng:
    "Discover our story and expertise with years of experience in the field",
  subTitleAr: "اكتشف قصتنا وخبرتنا مع سنوات من الخبرة في هذا المجال",
  textEng: "GET IN TOUCH!",
  textAr: "ابقى على تواصل!",
  placeHolderEng: "Enter your email address",
  placeHolderAr: "أدخل عنوان بريدك الالكتروني",
  btnTextEng: "SUBMIT",
  btnTextAr: "ارسال",
};
// 👇️ named exports
export {
  homeStartSectionData,
  qualityHomeSectionData,
  solutionsSectionData,
  workingWithUsSection,
  testimonialsSectionData,
  requestProfileData,
};
